import { Component, OnInit } from '@angular/core';
import { PopoverController, MenuController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import swal from '../../../assets/packages/sweetalert';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  process=false;
  disabled=false;
  store_id:any;
  sensor_id:any;
  deleteuser:any;
  sensor_unique_id:any='';
  store_holiday_id:any;
  complete_status=false;
  constructor(private modalCtrl:ModalController, private apiservice:ApiService, private navParam:NavParams) {
    
    this.store_id=this.navParam.get('store_id');
    this.sensor_id=this.navParam.get('sensor_id');
    this.sensor_unique_id=this.navParam.get('ID');
    this.deleteuser=this.navParam.get('deleteuser');
    this.store_holiday_id=this.navParam.get('store_holiday_id');
    this.complete_status=false;
   }

  ngOnInit() {}

  close(){
    if(this.complete_status){
    this.modalCtrl.dismiss(true)
    }else{
      this.modalCtrl.dismiss()
    }
  }

  delete_confirm(){
    if(this.store_id != undefined){
      this.store_delete()
    }else if(this.sensor_id != undefined){
      this.sensor_delete();
    }else if(this.deleteuser != undefined) {
      this.user_delete();
    }else if(this.store_holiday_id != undefined){
      this.delete_holiday();
    }
  }

  user_delete(){ 
    this.process=true;
    this.disabled=true;       
    let req='adminmodule?store_id='+this.deleteuser.store_id+'&user_id='+this.deleteuser.user_id+'&user_email='+this.deleteuser.email+'&role='+this.deleteuser.role+'&admin_id='+this.deleteuser.user_id+'&owner_id='+this.deleteuser.user_id;
    //(req);
    this.apiservice.deleteuser(req)
            .subscribe(
              resultArray => {
                let res:any=[];
                res=resultArray;
                this.process=false;
                this.disabled=false;
                //(res);
                this.complete_status=true;
                setTimeout(()=>{
                  this.modalCtrl.dismiss(true);
                }, 500);
                // swal(
                //   'Thank you!',
                //   'User deleted successfully',
                //   'success',
                //   {  closeOnClickOutside: false  }
                //   ).then(() => {
                //     this.modalCtrl.dismiss(true);
                //   }) 
                
               // this.open_modal('success', 'User deleted successfully');            
              },               
              error => {
                //(error);
                this.process=false;
                this.disabled=false;
                swal(
                  '',
                  'Database error. Please try again later.',
                  'error',
                  {  closeOnClickOutside: false  }
                  ).then(() => {
                    this.modalCtrl.dismiss(false);
                  })
               }) 
}

async open_modal(type, message){
    this.modalCtrl.dismiss(true);
  const modal = await this.modalCtrl.create({
    component: AlertPopupComponent,
    cssClass:'popupmodal',
    backdropDismiss:false,
    componentProps: { 
      type:type,
      message:message
    }
    });
    modal.onDidDismiss()
    .then((data) => {
     
     });
    return await modal.present();
  
}

  store_delete(){
    this.process=true;
    this.disabled=true;
    let req='stores?store_id='+this.store_id;
     //(req);
    this.apiservice.deleteapi(req)
         .subscribe(
           resultArray => {
             
             let res:any=[];
             res=resultArray;
             this.process=false;
             this.disabled=false;
             //(res);
             this.complete_status=true;
             setTimeout(()=>{
              this.modalCtrl.dismiss(true);
            }, 500);
           },
           error => {
            //(error);
             this.process=false;
             this.disabled=false;
             swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
                this.modalCtrl.dismiss(false);
              })
            })
   }

   sensor_delete(){
    //(this.sensor_id)
    this.process=true;
    this.disabled=true;
    let req='sensordata/sensordatabystore?id='+this.sensor_unique_id;
     //(req);
    this.apiservice.deleteapi(req)
         .subscribe(
           resultArray => {
             
             let res:any=[];
             res=resultArray;
             this.process=false;
             this.disabled=false;
             //(res);
             this.complete_status=true;
             setTimeout(()=>{
              this.modalCtrl.dismiss(true);
            }, 500);
           },
           error => {
           
           //(error);
             this.process=false;
             this.disabled=false;
             swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
                this.modalCtrl.dismiss(false);
              })
            })
   }

   delete_holiday(){
    this.process=true;
    this.disabled=true;
    let req="stores/store-holidays?id="+this.store_holiday_id;
     //(req);
    this.apiservice.deleteapi(req)
         .subscribe(
           resultArray => {
             
             let res:any=[];
             res=resultArray;
             this.process=false;
             this.disabled=false;
             //(res);
             this.complete_status=true;
             setTimeout(()=>{
              this.modalCtrl.dismiss(true);
            }, 500);
           },
           error => {
            //(error);
             this.process=false;
             this.disabled=false;
             swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
                this.modalCtrl.dismiss(false);
              })
            })
   }
 
 
}
