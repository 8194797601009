import { Component, OnInit } from '@angular/core';
import {  ModalController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { ApiService } from 'src/app/services/api.service';
import swal from '../../../assets/packages/sweetalert';
declare var AWS: any;
declare var require: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  type='password';
  submit=false;
  current_password:any='';
  new_password:any='';
  verify_password:any='';
  process=false;
  pmismatch=false;
  validpassword=true;
  errormsg=false;
  errortext='';
  type1='password';
  type2='password';
  done_status=false;
  constructor(private modalCtrl:ModalController, private router:Router, public apiservice:ApiService, private alertCtrl:AlertController) { }

  ngOnInit() {}

  
  //password icon toggle fun
  password_toggle() {
    if (this.type == 'text') {
      this.type = 'password'
    } else {
      this.type = 'text';
      setTimeout(() => {
        this.type = 'password'
      }, 500)
    }
  }
   
  //password icon toggle fun
  password_toggle1() {
    if (this.type1 == 'text') {
      this.type1 = 'password'
    } else {
      this.type1 = 'text';
      setTimeout(() => {
        this.type1 = 'password'
      }, 500)
    }
  }
 
  //password icon toggle fun
  password_toggle2() {
    if (this.type2 == 'text') {
      this.type2 = 'password'
    } else {
      this.type2 = 'text';
      setTimeout(() => {
        this.type2 = 'password'
      }, 500)
    }
  }


  password_validate() {
    if (this.new_password != '') {
      var regexp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

      if (regexp.test(this.new_password)) {
        this.validpassword = true;
      } else {
        this.validpassword = false;
      }
    }
  }

  change_password(){
        this.submit=true;
    if(this.current_password!='' && this.new_password!='' && this.verify_password!='' && this.validpassword){
      if(this.new_password==this.verify_password){
        this.pmismatch=false;
      this.changepassword();
      }else{
        this.pmismatch=true;
      }
    }
  }

  changepassword(){
    
    this.process=true;
    const AWS = require('aws-sdk');
    AWS.config.update({ region: 'us-east-1' });
      this.process = true;
      let instance = this;
      var poolData = {
        UserPoolId: this.apiservice.UserPoolId,
        ClientId: this.apiservice.ClientId
      }
  
      let AccessToken= localStorage.getItem("accessToken");
      //(AccessToken);
      
      var params = {
        AccessToken: AccessToken, /* required */
        PreviousPassword: this.current_password, /* required */
        ProposedPassword: this.new_password /* required */
      };
      
      var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.changePassword(params, function(err, data) {
        if (err)
        {
          //(err);
          
          if(err=='LimitExceededException: Attempt limit exceeded, please try after some time.'){
            instance.errortext='Attempt limit exceeded, Please try again later.'
          }else{
            instance.errortext='Old password is incorrect.'
          }
          instance.process=false;
          instance.errormsg=true;
           //(err, err.stack); // an error occurred
        }
        else   
        {
          instance.process=false;
          instance.errormsg=false;
          instance.done_status=true;
          setTimeout(()=>{
            instance.modalCtrl.dismiss();
            instance.router.navigateByUrl('login');
          }, 1500)
           //(data);           // successful response
        }
      });
  }

  //disacard changes code starts
  closeModal(){
    this.modalCtrl.dismiss();
  }

  modal_close(){
    if(this.current_password=='' && this.new_password=='' && this.verify_password==''){
      this.closeModal();
    }else{
      this.close_password_modal();
    }
 
  }

  async close_password_modal(){
    await this.alertCtrl.create({
      subHeader: 'Discard unsaved changes?',
      cssClass:'alertlog',
      
      buttons: [
      {
      text: "Cancel",
      },
      {
      text: "Discard", handler: (res) => {
        this.modalCtrl.dismiss();
        this.closeModal();
      }
      }
      ]
      }).then(res => res.present());
    }
   //disacard changes code ends


  forgot_password() {
    this.modalCtrl.dismiss()
    this.router.navigate(['/forgot-password']);
  }

  cancel(){
    this.router.navigate(['/settings']);
  }
}
