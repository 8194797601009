import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Observable } from 'rxjs';




@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return Auth.currentAuthenticatedUser().then(() => { 
      return true; 
    }).catch(() => {
        delete localStorage[`amplify-redirected-from-hosted-ui`];
        this._router.navigate(['/login']);
        return false;
      });

    return true;

  }
}