const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_EdY77wX5u",
    "aws_user_pools_web_client_id": "1b68a9j655qv4cugejiv5eq8si",
    "oauth": {
        "domain": "dev-foodbeacon-io.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dev.foodbeacon.io/",
        "redirectSignOut": "https://dev.foodbeacon.io/",
        "responseType": "code"
    }
};


export default awsmobile;