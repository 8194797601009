import { Injectable } from '@angular/core';
import { Observable, of  } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js';
//import { environment, apiUrl } from '../../environments/environment';
import { environment, SERVER_URL,  UserPoolId, ClientId, ApiKey} from '../../environments/environment';
import { Auth, Hub } from 'aws-amplify';
var navigator;

interface ItemsResponse { }

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: string = "";
  user_email: any = '';
  user_id: any = '';
  username: any = '';
  loginemail: any = '';
  loginpassword: any = '';
  usertype: any = '';
  store_id: any = '';
  apiUrl:any='';
  listofusers;  
  UserPoolId=UserPoolId;
  ClientId=ClientId;
  ApiKey=ApiKey;
  menu_sel='managestores';
  slider_enabled:boolean;
  constructor(public http: HttpClient, private toast: ToastController, public network: Network) { 
    //(SERVER_URL)
  //(this.UserPoolId);
  //(this.ClientId);
  }
  //Rest API method
  get_data(action): Observable<ItemsResponse> {
    if (action != '') {
      const httpOptions = {
      };
      return this.http.get(SERVER_URL + action, httpOptions)
        .pipe(map((response: Response) => {
          return response;
        }))
    } else {
      return this.http.get('http://localhost:1111/temperature')
        .pipe(map((response: Response) => {
          return response;
        }))
    }
  }

  get_local_data(): Observable<ItemsResponse> {
    return this.http.get('http://localhost:1111/store_info')
    .pipe(map((response: Response) => {
      return response;
    }))
  }

  apicall_re_inviteuser(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    const body= {};
  
   return this.http.post<any>(SERVER_URL+action,body,httpOptions )
     .pipe(map((response: Response) => {
       return response;
   }))
  }

  delapi(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    return this.http.delete(action, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapi(): Observable<ItemsResponse> {
    return this.http.get('http://localhost:1111/storelist')
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapipost(prod): Observable<ItemsResponse> {
    return this.http.post('http://localhost:1111/storelist', prod)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  localapipostupdate(prod, id): Observable<ItemsResponse> {
    return this.http.put('http://localhost:1111/storelist/' + id, prod)
      .pipe(map((response: Response) => {
        return response;
      }))
  }


//delete api call
  deleteapi(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    return this.http.delete(SERVER_URL+action, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  //get api call

  apicall(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    return this.http.get(SERVER_URL+action,httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  //post api call
  apicallsignup(action): Observable<ItemsResponse> {
    const options = {
    };
    const body= {};
    return this.http.post<any>(SERVER_URL+action,body,options)
      .pipe(map((response: Response) => {
        
        return response;
      }))
  }

//update api call
  apicallupdate(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    const body= {};
    return this.http.put(SERVER_URL+action,body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }
  apicall_inviteuser(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    const body= {};
    return this.http.post<any>(SERVER_URL+action,body, httpOptions)
      .pipe(map((response: Response) => {
        return response;
      }))
  }

  apicall_edituser(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    const body= {};
   return this.http.post<any>(SERVER_URL+action,body,httpOptions)
     .pipe(map((response: Response) => {
       return response;
   }))
  }
  
  //delete api call
  deleteuser(action): Observable<ItemsResponse> {
    const httpOptions = {
    };
    return this.http.delete(SERVER_URL+action, httpOptions)
        .pipe(map((response: Response) => {
          return response;
      }))
  }

  checkconnection() {

    if (this.network.type === 'wifi') {

      return true;
    } else {
      return false;
    }
  }

  async presentToast() {
   
      const toast = await this.toast.create({
      //  header: 'Toast header',
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        position: 'bottom',
        cssClass:'toast-cls',
        buttons: [
          {
            text: 'Allow Cookies',
            role: 'cancel',
            cssClass:'toast-btn',
            handler: () => {
              //('Cancel clicked');
            }
          }
        ]
      });
      toast.present();
  }

  adduserlist(list){
    //(".............list in service page", list);
    this.listofusers = list;
  }

  userlistdetails(): Observable<any[]>{
     return of(this.listofusers);
   }

   //download csv for sensor data
  //  get_data(action): Observable<ItemsResponse> {
  //   if (action != '') {
  //   return this.http.get(SERVER_URL + action)
  //   .pipe(map((response: Response) => {
  //   return response;
  //   }))
  //   } else {
  //   return this.http.get('http://localhost:1111/')
  //   .pipe(map((response: Response) => {
  //   return response;
  //   }))
  //   }
  //   }
}
