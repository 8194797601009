import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AdminDropdownComponent } from 'src/app/popover/admin-dropdown/admin-dropdown.component';
import { PopoverController, ModalController, NavParams, AlertController } from '@ionic/angular';
import { ProfileComponent } from 'src/app/popover/profile/profile.component';
import { Router, Data } from '@angular/router';
import swal from '../../../assets/packages/sweetalert';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material';

@Component({
selector: 'app-add-sensor',
templateUrl: './add-sensor.component.html',
styleUrls: ['./add-sensor.component.scss'],
})
export class AddSensorComponent implements OnInit {
done_status=false;
username:any='';
sensor_id:any='';
Store_id:any='';
delay:any='3600';
min_temp:any='';
max_temp:any='';
submit=false;
sensor_des:any='';
sensor_name:any='';
invaliddelay=false;
invalidsensorid=false;
invalidmaxtemp=false;
invalidmintemp=false;
process=false;
disabled=false;
sensordata:any;
user_id:any='';
validatename=true;
unique_id:any='';
old_sensorid:any='';
invalid_desc=false;
duplicate_sensor=false;
constructor(private alertCtrl:AlertController, private navParam:NavParams, private modalCtrl:ModalController, private apiservice:ApiService, private popoverController:PopoverController, private router:Router) {
this.sensordata=this.navParam.get('data');
if(this.sensordata!=undefined){
this.sensor_name=this.sensordata.sensor_name;
this.sensor_id=this.sensordata.device_id;
this.old_sensorid=this.sensordata.device_id;
this.sensor_des=this.sensordata.sensor_description;
this.delay=this.sensordata.delay;
let t1=this.sensordata.preset_min;
this.min_temp=t1.toString();
let t2=this.sensordata.preset_max;
this.max_temp=t2.toString();
this.unique_id=this.sensordata.ID;
this.done_status=false;
}
this.Store_id=localStorage.getItem("store_id");
}

ngOnInit() {
let usercredentials=JSON.parse(localStorage.getItem("userData"));
this.user_id=usercredentials[0].ID;
}

add_sensor(){
this.submit=true;
if(!this.invalid_desc && this.sensor_id!='' && this.delay!='' && this.min_temp!='' && this.max_temp!='' && this.sensor_des!='' && this.sensor_name!='' && !this.invaliddelay && !this.invalidmintemp && !this.invalidmaxtemp && this.validatename && !this.invalidsensorid){
this.add_sensor_api();
}
}

//disacard changes code starts
closeModal(){
this.modalCtrl.dismiss();
}

modal_close(){

if(this.sensordata==undefined){
if(this.sensor_name==''&& this.sensor_des=='' && this.sensor_id=='' && this.delay=='3600' && this.min_temp=='' && this.max_temp==''){
this.closeModal();
}else{
this.close_addsensor_modal();
}
}else{
if(this.sensor_name==this.sensordata.sensor_name
&& this.sensor_id==this.sensordata.device_id
&& this.sensor_des==this.sensordata.sensor_description
&& this.delay==this.sensordata.delay
&& this.min_temp==this.sensordata.preset_min
&& this.max_temp == this.sensordata.preset_max){
this.closeModal();
}else{
this.close_addsensor_modal();
}
}
}

async close_addsensor_modal(){
await this.alertCtrl.create({
subHeader: 'Discard unsaved changes?',
cssClass:'alertlog',

buttons: [
{
text: "Cancel",
},
{
text: "Discard", handler: (res) => {
this.modalCtrl.dismiss();
this.closeModal();

}
}
]
}).then(res => res.present());
}

//disacard changes code ends

name_validate(){
if(this.sensor_name!==''){
if(/[!@#$%^&*(),.?":{}|<>]/.test(this.sensor_name)){
this.validatename=false;
}else{
this.validatename=true;
}
}
}

update_sensor(){
this.submit=true;
if(!this.invalid_desc && this.sensor_id!='' && this.delay!='' && this.min_temp!='' && this.max_temp!='' && this.sensor_des!='' && this.sensor_name!='' && !this.invaliddelay && !this.invalidmintemp && !this.invalidmaxtemp && this.validatename && !this.invalidsensorid){
this.update_sensor_api();
}
}

add_sensor_api(){
this.process=true;
this.disabled=true;
let desc=encodeURIComponent(this.sensor_des);
//(desc);
let id=encodeURIComponent(this.sensor_id);
let name = encodeURIComponent(this.sensor_name);
let req='sensordata/sensordatabystore?device_id='+id+'&store_id='+this.Store_id+'&preset_max='+this.max_temp+'&preset_min='+this.min_temp+'&delay='+this.delay+'&sensor_description='+desc+'&sensor_name='+name;
//(req);
this.apiservice.apicallsignup(req)
.subscribe(
resultArray => {

let res:any=[];
res=resultArray;

this.process=false;
this.disabled=false;
if(res.errorMessage=='this store does not exist'){
swal(
'Thank you!',
'Invalid store, Please select valid store',
'error',
{ closeOnClickOutside: false }
).then(() => {
    this.done_status=false;
})
}else if(res.errorMessage=='duplicate device id'){
// swal(
// '',
// 'Sensor ID already exists',
// 'warning',
// { closeOnClickOutside: false }

// ).then(() => {
//     this.done_status=false;
// })
this.duplicate_sensor=true;
}else{
 this.done_status=true;
this.duplicate_sensor=false;
setTimeout(()=>{
this.modalCtrl.dismiss(true);
}, 500);

}
},
error => {
//(error);
this.process=false;
this.disabled=false;
swal(
'',
'Database error. Please try again later.',
'error',
{ closeOnClickOutside: false }
).then(() => {
this.modalCtrl.dismiss(false);
})
})
}


id_change(){
    this.duplicate_sensor=false;
    this.done_status=false;
    this.process=false;
}
update_sensor_api(){
this.process=true;
this.disabled=true;

let sen_des=encodeURIComponent(this.sensor_des);
//(sen_des);
let id=encodeURIComponent(this.sensor_id);
let old_id=encodeURIComponent(this.old_sensorid);
let name = encodeURIComponent(this.sensor_name);
let req='sensordata/sensordatabystore?device_id='+id+'&store_id='+this.Store_id+'&sensor_name='+name+'&sensor_description='+sen_des+'&preset_max='+this.max_temp+'&preset_min='+this.min_temp+'&delay='+this.delay+'&user_id='+this.user_id+'&id='+this.unique_id;
//(req);
this.apiservice.apicallupdate(req)
.subscribe(
resultArray => {

let res:any=[];
res=resultArray;
this.process=false;
this.disabled=false;

if(res.errorMessage=='this store does not exist'){
swal(
'Thank you!',
'Invalid store, Please select valid store',
'error',
{ closeOnClickOutside: false }
).then(() => {

})
}else if(res.errorMessage=='duplicate sensor id'){

this.duplicate_sensor=true;
}else{
    this.done_status=true;
this.duplicate_sensor=false;

this.done_status=true;
setTimeout(()=>{
this.modalCtrl.dismiss(true);
}, 500);
}


},
error => {
//(error);
this.process=false;
this.disabled=false;
swal(
'',
'Database error. Please try again later.',
'error',
{ closeOnClickOutside: false }
).then(() => {
this.modalCtrl.dismiss(false);
})
})
}

delay_validate(){
this.done_status=false;
if(this.delay!=''&&this.delay!=null){
if(/^\d*$/.test(this.delay)){

this.invaliddelay=false;
}else{

this.invaliddelay=true;
}
}else{
this.invaliddelay=true;
}
}



sensorid_validate(){

if(this.sensor_id!=''&& this.sensor_id!=null){
if( /^[ A-Za-z0-9_@./#&+]*$/.test(this.sensor_id)){

this.invalidsensorid=false;
}else{

this.invalidsensorid=true;
}
}else{
this.invalidsensorid=true;
}

}

mintemp_validate(){

let min_value=this.min_temp;
if(min_value!=''&& min_value!=null){
let value= parseInt(min_value);
if( /^[ 0-9_-]*$/.test(min_value)){

this.invalidmintemp=false;
}else{

this.invalidmintemp=true;
}
}else{
this.invalidmintemp=true;
}

}

maxtemp_validate(){
let max_value=this.max_temp;
if(max_value!='' && max_value!=null ){
if(/^[ 0-9_-]*$/.test( max_value)){

this.invalidmaxtemp=false;
}else{

this.invalidmaxtemp=true;
}
}else{
this.invalidmaxtemp=true;
}
}


cancel(){
this.router.navigate(['/managestores']);
}

ionViewDidEnter(){
}

close(){

this.modalCtrl.dismiss()
}

desc_validate(){

let l=this.sensor_des.length;
if(l > 255){
this.invalid_desc=true;
}else{
this.invalid_desc=false;
}
}


}