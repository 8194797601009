import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PopoverController, MenuController, ModalController, NavParams, AlertController, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import swal from '../../../assets/packages/sweetalert';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import * as moment from 'moment';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { Renderer2, ViewChild, Input, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeleteComponent } from 'src/app/modals/delete/delete.component';
import { ThrowStmt, IfStmt } from '@angular/compiler';
import { ignoreElements } from 'rxjs/operators';
@Component({
  selector: 'app-addstore',
  templateUrl: './addstore.component.html',
  styleUrls: ['./addstore.component.scss'],
})
export class AddstoreComponent implements OnInit {
  disabled=false;
  submit=false;
  phone:any='';
  name:any='';
  country:any='';
  sel_country:any='';
  country_change:any='';
  address:any='';
  process=false;
  userid:any='';
  invalidphone=true;
  objectlist:any=[];
  storedata:any;
  store_id:any='';
  validatename=true;
  userrole:any='';
  sel_seg:any='0';
  time='';
  prev_cliked:any='';
  dynamic_class='';
  open_date1:any='';
  prev_open_date1:any='';
  sel_open_date1:any='';
  open_date2:any='';
  prev_open_date2:any='';
  sel_open_date2:any='';
  open_date3:any='';
  prev_open_date3:any='';
  sel_open_date3:any='';
  open_date4:any='';
  prev_open_date4:any='';
  sel_open_date4:any='';
  open_date5:any='';
  prev_open_date5:any='';
  sel_open_date5:any='';
  open_date6:any='';
  prev_open_date6:any='';
  sel_open_date6:any='';
  open_date7:any='';
  prev_open_date7:any='';
  sel_open_date7:any='';
  close_date1:any='';
  prev_close_date1:any='';
  sel_close_date1:any='';
  close_date2:any='';
  prev_close_date2:any='';
  sel_close_date2:any='';
  close_date3:any='';
  prev_close_date3:any='';
  sel_close_date3:any='';
  close_date4:any='';
  prev_close_date4:any='';
  sel_close_date4:any='';
  close_date5:any='';
  prev_close_date5:any='';
  sel_close_date5:any='';
  close_date6:any='';
  prev_close_date6:any='';
  sel_close_date6:any='';
  close_date7:any='';
  prev_close_date7:any='';
  sel_close_date7:any='';
  open_close1:any=false;
  sel_open_close1:any=false;
  open_close2:any=false;
  sel_open_close2:any=false;
  open_close3:any=false;
  sel_open_close3:any=false;
  open_close4:any=false;
  sel_open_close4:any=false;
  open_close5:any=false;
  sel_open_close5:any=false;
  open_close6:any=false;
  sel_open_close6:any=false;
  open_close7:any=false;
  sel_open_close7:any=false;
  submit_hours=false;
  maxlength1:any=8;
  maxlength11:any=8;
  details_value:any;
  listofHolidayDates;
  public holidays_array: any[] = [];
  valid1=true;
  valid11=true;
  valid2=true;
  valid22=true;
  valid3=true;
  valid33=true;
  valid4=true;
  valid44=true;
  valid5=true;
  valid55=true;
  valid6=true;
  valid66=true;
  valid7=true;
  valid77=true;
  timer_div1=false;
  timer_div11=false;
  timer_div2=false;
  timer_div22=false;
  timer_div3=false;
  timer_div33=false;
  timer_div4=false;
  timer_div44=false;
  timer_div5=false;
  timer_div55=false;
  timer_div6=false;
  timer_div66=false;
  timer_div7=false;
  timer_div77=false;
  monday_validtime=false;
  tuesday_validtime=false;
  wednesday_validtime=false;
  thursday_validtime=false;
  friday_validtime=false;
  saturday_validtime=false;
  sunday_validtime=false;
  duplicate_store=false;
  hour1:any='06';
  minutes1:any=30;
  t1='AM';
  hour11:any='06';
  minutes11:any=30;
  t11='AM';
  hour2:any='06';
  minutes2:any=30;
  t2='AM';
  hour22:any='06';
  minutes22:any=30;
  t22='AM';
  hour3:any='06';
  minutes3:any=30;
  t3='AM';
  hour33:any='06';
  minutes33:any=30;
  t33='AM';
  hour4:any='06';
  minutes4:any=30;
  t4='AM';
  hour44:any='06';
  minutes44:any=30;
  t44='AM';
  hour5:any='06';
  minutes5:any=30;
  t5='AM';
  hour55:any='06';
  minutes55:any=30;
  t55='AM';
  hour6:any='06';
  minutes6:any=30;
  t6='AM';
  hour66:any='06';
  minutes66:any=30;
  t66='AM';
  hour7:any='06';
  minutes7:any=30;
  t7='AM';
  hour77:any='06';
  minutes77:any=30;
  t77='AM';
  holiday_date:any='';
  holiday_name:any='';
  show_more=false;
  multiple_holidays:any=[{'holiday_name':'', 'holiday_date':''}];
  inputType_calender:any=false;
  weeks=['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  submit_holiday:any=false;
  store_holiday_data:any=[];
  prev_store_data:any=[];
  temp_values:any=[];
  prev_sel_data:any='';
  edit_data:any=false;
  today:any;
  diff = [];
  complete:any;
  complete_status:any;
  timezones= 
  [
    {
      "value": "Dateline Standard Time",
      "abbr": "DST",
      "offset": -12,
      "isdst": false,
      "text": "(UTC-12:00) International Date Line West",
      "utc": [
        "Etc/GMT+12"
      ]
    },
    {
      "value": "UTC-11",
      "abbr": "U",
      "offset": -11,
      "isdst": false,
      "text": "(UTC-11:00) Coordinated Universal Time-11",
      "utc": [
        "Etc/GMT+11",
        "Pacific/Midway",
        "Pacific/Niue",
        "Pacific/Pago_Pago"
      ]
    },
    {
      "value": "Hawaiian Standard Time",
      "abbr": "HST",
      "offset": -10,
      "isdst": false,
      "text": "(UTC-10:00) Hawaii",
      "utc": [
        "Etc/GMT+10",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Rarotonga",
        "Pacific/Tahiti"
      ]
    },
    {
      "value": "Alaskan Standard Time",
      "abbr": "AKDT",
      "offset": -8,
      "isdst": true,
      "text": "(UTC-09:00) Alaska",
      "utc": [
        "America/Anchorage",
        "America/Juneau",
        "America/Nome",
        "America/Sitka",
        "America/Yakutat"
      ]
    },
    {
      "value": "Pacific Standard Time (Mexico)",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-08:00) Baja California",
      "utc": [
        "America/Santa_Isabel"
      ]
    },
    {
      "value": "Pacific Daylight Time",
      "abbr": "PDT",
      "offset": -7,
      "isdst": true,
      "text": "(UTC-07:00) Pacific Time (US & Canada)",
      "utc": [
        "America/Dawson",
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "America/Whitehorse"
      ]
    },
    {
      "value": "Pacific Standard Time",
      "abbr": "PST",
      "offset": -8,
      "isdst": false,
      "text": "(UTC-08:00) Pacific Time (US & Canada)",
      "utc": [
        "America/Dawson",
        "America/Los_Angeles",
        "America/Tijuana",
        "America/Vancouver",
        "America/Whitehorse",
        "PST8PDT"
      ]
    },
    {
      "value": "US Mountain Standard Time",
      "abbr": "UMST",
      "offset": -7,
      "isdst": false,
      "text": "(UTC-07:00) Arizona",
      "utc": [
        "America/Creston",
        "America/Dawson_Creek",
        "America/Hermosillo",
        "America/Phoenix",
        "Etc/GMT+7"
      ]
    },
    {
      "value": "Mountain Standard Time (Mexico)",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      "utc": [
        "America/Chihuahua",
        "America/Mazatlan"
      ]
    },
    {
      "value": "Mountain Standard Time",
      "abbr": "MDT",
      "offset": -6,
      "isdst": true,
      "text": "(UTC-07:00) Mountain Time (US & Canada)",
      "utc": [
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Denver",
        "America/Edmonton",
        "America/Inuvik",
        "America/Ojinaga",
        "America/Yellowknife",
        "MST7MDT"
      ]
    },
    {
      "value": "Central America Standard Time",
      "abbr": "CAST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Central America",
      "utc": [
        "America/Belize",
        "America/Costa_Rica",
        "America/El_Salvador",
        "America/Guatemala",
        "America/Managua",
        "America/Tegucigalpa",
        "Etc/GMT+6",
        "Pacific/Galapagos"
      ]
    },
    {
      "value": "Central Standard Time",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Central Time (US & Canada)",
      "utc": [
        "America/Chicago",
        "America/Indiana/Knox",
        "America/Indiana/Tell_City",
        "America/Matamoros",
        "America/Menominee",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Resolute",
        "America/Winnipeg",
        "CST6CDT"
      ]
    },
    {
      "value": "Central Standard Time (Mexico)",
      "abbr": "CDT",
      "offset": -5,
      "isdst": true,
      "text": "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      "utc": [
        "America/Bahia_Banderas",
        "America/Cancun",
        "America/Merida",
        "America/Mexico_City",
        "America/Monterrey"
      ]
    },
    {
      "value": "Canada Central Standard Time",
      "abbr": "CCST",
      "offset": -6,
      "isdst": false,
      "text": "(UTC-06:00) Saskatchewan",
      "utc": [
        "America/Regina",
        "America/Swift_Current"
      ]
    },
    {
      "value": "SA Pacific Standard Time",
      "abbr": "SPST",
      "offset": -5,
      "isdst": false,
      "text": "(UTC-05:00) Bogota, Lima, Quito",
      "utc": [
        "America/Bogota",
        "America/Cayman",
        "America/Coral_Harbour",
        "America/Eirunepe",
        "America/Guayaquil",
        "America/Jamaica",
        "America/Lima",
        "America/Panama",
        "America/Rio_Branco",
        "Etc/GMT+5"
      ]
    },
    {
      "value": "Eastern Standard Time",
      "abbr": "EDT",
      "offset": -4,
      "isdst": true,
      "text": "(UTC-05:00) Eastern Time (US & Canada)",
      "utc": [
        "America/Detroit",
        "America/Havana",
        "America/Indiana/Petersburg",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Iqaluit",
        "America/Kentucky/Monticello",
        "America/Louisville",
        "America/Montreal",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Pangnirtung",
        "America/Port-au-Prince",
        "America/Thunder_Bay",
        "America/Toronto",
        "EST5EDT"
      ]
    },
    {
      "value": "US Eastern Standard Time",
      "abbr": "UEDT",
      "offset": -4,
      "isdst": true,
      "text": "(UTC-05:00) Indiana (East)",
      "utc": [
        "America/Indiana/Marengo",
        "America/Indiana/Vevay",
        "America/Indianapolis"
      ]
    },
    {
      "value": "Venezuela Standard Time",
      "abbr": "VST",
      "offset": -4.5,
      "isdst": false,
      "text": "(UTC-04:30) Caracas",
      "utc": [
        "America/Caracas"
      ]
    },
    {
      "value": "Paraguay Standard Time",
      "abbr": "PYT",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Asuncion",
      "utc": [
        "America/Asuncion"
      ]
    },
    {
      "value": "Atlantic Standard Time",
      "abbr": "ADT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-04:00) Atlantic Time (Canada)",
      "utc": [
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Halifax",
        "America/Moncton",
        "America/Thule",
        "Atlantic/Bermuda"
      ]
    },
    {
      "value": "Central Brazilian Standard Time",
      "abbr": "CBST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Cuiaba",
      "utc": [
        "America/Campo_Grande",
        "America/Cuiaba"
      ]
    },
    {
      "value": "SA Western Standard Time",
      "abbr": "SWST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
      "utc": [
        "America/Anguilla",
        "America/Antigua",
        "America/Aruba",
        "America/Barbados",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Curacao",
        "America/Dominica",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guyana",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lower_Princes",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Montserrat",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Santo_Domingo",
        "America/St_Barthelemy",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Tortola",
        "Etc/GMT+4"
      ]
    },
    {
      "value": "Pacific SA Standard Time",
      "abbr": "PSST",
      "offset": -4,
      "isdst": false,
      "text": "(UTC-04:00) Santiago",
      "utc": [
        "America/Santiago",
        "Antarctica/Palmer"
      ]
    },
    {
      "value": "Newfoundland Standard Time",
      "abbr": "NDT",
      "offset": -2.5,
      "isdst": true,
      "text": "(UTC-03:30) Newfoundland",
      "utc": [
        "America/St_Johns"
      ]
    },
    {
      "value": "E. South America Standard Time",
      "abbr": "ESAST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Brasilia",
      "utc": [
        "America/Sao_Paulo"
      ]
    },
    {
      "value": "Argentina Standard Time",
      "abbr": "AST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Buenos Aires",
      "utc": [
        "America/Argentina/La_Rioja",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Buenos_Aires",
        "America/Catamarca",
        "America/Cordoba",
        "America/Jujuy",
        "America/Mendoza"
      ]
    },
    {
      "value": "SA Eastern Standard Time",
      "abbr": "SEST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Cayenne, Fortaleza",
      "utc": [
        "America/Araguaina",
        "America/Belem",
        "America/Cayenne",
        "America/Fortaleza",
        "America/Maceio",
        "America/Paramaribo",
        "America/Recife",
        "America/Santarem",
        "Antarctica/Rothera",
        "Atlantic/Stanley",
        "Etc/GMT+3"
      ]
    },
    {
      "value": "Greenland Standard Time",
      "abbr": "GDT",
      "offset": -3,
      "isdst": true,
      "text": "(UTC-03:00) Greenland",
      "utc": [
        "America/Godthab"
      ]
    },
    {
      "value": "Montevideo Standard Time",
      "abbr": "MST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Montevideo",
      "utc": [
        "America/Montevideo"
      ]
    },
    {
      "value": "Bahia Standard Time",
      "abbr": "BST",
      "offset": -3,
      "isdst": false,
      "text": "(UTC-03:00) Salvador",
      "utc": [
        "America/Bahia"
      ]
    },
    {
      "value": "UTC-02",
      "abbr": "U",
      "offset": -2,
      "isdst": false,
      "text": "(UTC-02:00) Coordinated Universal Time-02",
      "utc": [
        "America/Noronha",
        "Atlantic/South_Georgia",
        "Etc/GMT+2"
      ]
    },
    {
      "value": "Mid-Atlantic Standard Time",
      "abbr": "MDT",
      "offset": -1,
      "isdst": true,
      "text": "(UTC-02:00) Mid-Atlantic - Old",
      "utc": []
    },
    {
      "value": "Azores Standard Time",
      "abbr": "ADT",
      "offset": 0,
      "isdst": true,
      "text": "(UTC-01:00) Azores",
      "utc": [
        "America/Scoresbysund",
        "Atlantic/Azores"
      ]
    },
    {
      "value": "Cape Verde Standard Time",
      "abbr": "CVST",
      "offset": -1,
      "isdst": false,
      "text": "(UTC-01:00) Cape Verde Is.",
      "utc": [
        "Atlantic/Cape_Verde",
        "Etc/GMT+1"
      ]
    },
    {
      "value": "Morocco Standard Time",
      "abbr": "MDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Casablanca",
      "utc": [
        "Africa/Casablanca",
        "Africa/El_Aaiun"
      ]
    },
    {
      "value": "UTC",
      "abbr": "UTC",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Coordinated Universal Time",
      "utc": [
        "America/Danmarkshavn",
        "Etc/GMT"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GMT",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "British Summer Time",
      "abbr": "BST",
      "offset": 1,
      "isdst": true,
      "text": "(UTC+01:00) Edinburgh, London",
      "utc": [
        "Europe/Isle_of_Man",
        "Europe/Guernsey",
        "Europe/Jersey",
        "Europe/London"
      ]
    },
    {
      "value": "GMT Standard Time",
      "abbr": "GDT",
      "offset": 1,
      "isdst": true,
      "text": "(UTC) Dublin, Lisbon",
      "utc": [
        "Atlantic/Canary",
        "Atlantic/Faeroe",
        "Atlantic/Madeira",
        "Europe/Dublin",
        "Europe/Lisbon"
      ]
    },
    {
      "value": "Greenwich Standard Time",
      "abbr": "GST",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Monrovia, Reykjavik",
      "utc": [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Bamako",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Freetown",
        "Africa/Lome",
        "Africa/Monrovia",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Sao_Tome",
        "Atlantic/Reykjavik",
        "Atlantic/St_Helena"
      ]
    },
    {
      "value": "W. Europe Standard Time",
      "abbr": "WEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      "utc": [
        "Arctic/Longyearbyen",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Berlin",
        "Europe/Busingen",
        "Europe/Gibraltar",
        "Europe/Luxembourg",
        "Europe/Malta",
        "Europe/Monaco",
        "Europe/Oslo",
        "Europe/Rome",
        "Europe/San_Marino",
        "Europe/Stockholm",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Zurich"
      ]
    },
    {
      "value": "Central Europe Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      "utc": [
        "Europe/Belgrade",
        "Europe/Bratislava",
        "Europe/Budapest",
        "Europe/Ljubljana",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Tirane"
      ]
    },
    {
      "value": "Romance Standard Time",
      "abbr": "RDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      "utc": [
        "Africa/Ceuta",
        "Europe/Brussels",
        "Europe/Copenhagen",
        "Europe/Madrid",
        "Europe/Paris"
      ]
    },
    {
      "value": "Central European Standard Time",
      "abbr": "CEDT",
      "offset": 2,
      "isdst": true,
      "text": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      "utc": [
        "Europe/Sarajevo",
        "Europe/Skopje",
        "Europe/Warsaw",
        "Europe/Zagreb"
      ]
    },
    {
      "value": "W. Central Africa Standard Time",
      "abbr": "WCAST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) West Central Africa",
      "utc": [
        "Africa/Algiers",
        "Africa/Bangui",
        "Africa/Brazzaville",
        "Africa/Douala",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Luanda",
        "Africa/Malabo",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Porto-Novo",
        "Africa/Tunis",
        "Etc/GMT-1"
      ]
    },
    {
      "value": "Namibia Standard Time",
      "abbr": "NST",
      "offset": 1,
      "isdst": false,
      "text": "(UTC+01:00) Windhoek",
      "utc": [
        "Africa/Windhoek"
      ]
    },
    {
      "value": "GTB Standard Time",
      "abbr": "GDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Athens, Bucharest",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau"
      ]
    },
    {
      "value": "Middle East Standard Time",
      "abbr": "MEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Beirut",
      "utc": [
        "Asia/Beirut"
      ]
    },
    {
      "value": "Egypt Standard Time",
      "abbr": "EST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Cairo",
      "utc": [
        "Africa/Cairo"
      ]
    },
    {
      "value": "Syria Standard Time",
      "abbr": "SDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Damascus",
      "utc": [
        "Asia/Damascus"
      ]
    },
    {
      "value": "E. Europe Standard Time",
      "abbr": "EEDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) E. Europe",
      "utc": [
        "Asia/Nicosia",
        "Europe/Athens",
        "Europe/Bucharest",
        "Europe/Chisinau",
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Nicosia",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
  
      ]
    },
    {
      "value": "South Africa Standard Time",
      "abbr": "SAST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Harare, Pretoria",
      "utc": [
        "Africa/Blantyre",
        "Africa/Bujumbura",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Kigali",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Etc/GMT-2"
      ]
    },
    {
      "value": "FLE Standard Time",
      "abbr": "FDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      "utc": [
        "Europe/Helsinki",
        "Europe/Kiev",
        "Europe/Mariehamn",
        "Europe/Riga",
        "Europe/Sofia",
        "Europe/Tallinn",
        "Europe/Uzhgorod",
        "Europe/Vilnius",
        "Europe/Zaporozhye"
      ]
    },
    {
      "value": "Turkey Standard Time",
      "abbr": "TDT",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Istanbul",
      "utc": [
        "Europe/Istanbul"
      ]
    },
    {
      "value": "Israel Standard Time",
      "abbr": "JDT",
      "offset": 3,
      "isdst": true,
      "text": "(UTC+02:00) Jerusalem",
      "utc": [
        "Asia/Jerusalem"
      ]
    },
    {
      "value": "Libya Standard Time",
      "abbr": "LST",
      "offset": 2,
      "isdst": false,
      "text": "(UTC+02:00) Tripoli",
      "utc": [
        "Africa/Tripoli"
      ]
    },
    {
      "value": "Jordan Standard Time",
      "abbr": "JST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Amman",
      "utc": [
        "Asia/Amman"
      ]
    },
    {
      "value": "Arabic Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Baghdad",
      "utc": [
        "Asia/Baghdad"
      ]
    },
    {
      "value": "Kaliningrad Standard Time",
      "abbr": "KST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+02:00) Kaliningrad",
      "utc": [
        "Europe/Kaliningrad"
      ]
    },
    {
      "value": "Arab Standard Time",
      "abbr": "AST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Kuwait, Riyadh",
      "utc": [
        "Asia/Aden",
        "Asia/Bahrain",
        "Asia/Kuwait",
        "Asia/Qatar",
        "Asia/Riyadh"
      ]
    },
    {
      "value": "E. Africa Standard Time",
      "abbr": "EAST",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Nairobi",
      "utc": [
        "Africa/Addis_Ababa",
        "Africa/Asmera",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Mogadishu",
        "Africa/Nairobi",
        "Antarctica/Syowa",
        "Etc/GMT-3",
        "Indian/Antananarivo",
        "Indian/Comoro",
        "Indian/Mayotte"
      ]
    },
    {
      "value": "Moscow Standard Time",
      "abbr": "MSK",
      "offset": 3,
      "isdst": false,
      "text": "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
      "utc": [
         "Europe/Kirov",
        "Europe/Moscow",
        "Europe/Simferopol",
        "Europe/Volgograd",
        "Europe/Minsk"
      ]
    },
    {
      "value": "Samara Time",
      "abbr": "SAMT",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Samara, Ulyanovsk, Saratov",
      "utc": [
         "Europe/Astrakhan",
        "Europe/Samara",
         "Europe/Ulyanovsk"
      ]
    },
    {
      "value": "Iran Standard Time",
      "abbr": "IDT",
      "offset": 4.5,
      "isdst": true,
      "text": "(UTC+03:30) Tehran",
      "utc": [
        "Asia/Tehran"
      ]
    },
    {
      "value": "Arabian Standard Time",
      "abbr": "AST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Abu Dhabi, Muscat",
      "utc": [
        "Asia/Dubai",
        "Asia/Muscat",
        "Etc/GMT-4"
      ]
    },
    {
      "value": "Azerbaijan Standard Time",
      "abbr": "ADT",
      "offset": 5,
      "isdst": true,
      "text": "(UTC+04:00) Baku",
      "utc": [
        "Asia/Baku"
      ]
    },
    {
      "value": "Mauritius Standard Time",
      "abbr": "MST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Port Louis",
      "utc": [
        "Indian/Mahe",
        "Indian/Mauritius",
        "Indian/Reunion"
      ]
    },
    {
      "value": "Georgian Standard Time",
      "abbr": "GET",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Tbilisi",
      "utc": [
        "Asia/Tbilisi"
      ]
    },
    {
      "value": "Caucasus Standard Time",
      "abbr": "CST",
      "offset": 4,
      "isdst": false,
      "text": "(UTC+04:00) Yerevan",
      "utc": [
        "Asia/Yerevan"
      ]
    },
    {
      "value": "Afghanistan Standard Time",
      "abbr": "AST",
      "offset": 4.5,
      "isdst": false,
      "text": "(UTC+04:30) Kabul",
      "utc": [
        "Asia/Kabul"
      ]
    },
    {
      "value": "West Asia Standard Time",
      "abbr": "WAST",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Ashgabat, Tashkent",
      "utc": [
        "Antarctica/Mawson",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Dushanbe",
        "Asia/Oral",
        "Asia/Samarkand",
        "Asia/Tashkent",
        "Etc/GMT-5",
        "Indian/Kerguelen",
        "Indian/Maldives"
      ]
    },
    {
      "value": "Yekaterinburg Time",
      "abbr": "YEKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Yekaterinburg",
      "utc": [
        "Asia/Yekaterinburg"
      ]
    },
    {
      "value": "Pakistan Standard Time",
      "abbr": "PKT",
      "offset": 5,
      "isdst": false,
      "text": "(UTC+05:00) Islamabad, Karachi",
      "utc": [
        "Asia/Karachi"
      ]
    },
    {
      "value": "India Standard Time",
      "abbr": "IST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      "utc": [
        "Asia/Kolkata"
      ]
    },
    {
      "value": "Sri Lanka Standard Time",
      "abbr": "SLST",
      "offset": 5.5,
      "isdst": false,
      "text": "(UTC+05:30) Sri Jayawardenepura",
      "utc": [
        "Asia/Colombo"
      ]
    },
    {
      "value": "Nepal Standard Time",
      "abbr": "NST",
      "offset": 5.75,
      "isdst": false,
      "text": "(UTC+05:45) Kathmandu",
      "utc": [
        "Asia/Kathmandu"
      ]
    },
    {
      "value": "Central Asia Standard Time",
      "abbr": "CAST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Nur-Sultan (Astana)",
      "utc": [
        "Antarctica/Vostok",
        "Asia/Almaty",
        "Asia/Bishkek",
        "Asia/Qyzylorda",
        "Asia/Urumqi",
        "Etc/GMT-6",
        "Indian/Chagos"
      ]
    },
    {
      "value": "Bangladesh Standard Time",
      "abbr": "BST",
      "offset": 6,
      "isdst": false,
      "text": "(UTC+06:00) Dhaka",
      "utc": [
        "Asia/Dhaka",
        "Asia/Thimphu"
      ]
    },
    {
      "value": "Myanmar Standard Time",
      "abbr": "MST",
      "offset": 6.5,
      "isdst": false,
      "text": "(UTC+06:30) Yangon (Rangoon)",
      "utc": [
        "Asia/Rangoon",
        "Indian/Cocos"
      ]
    },
    {
      "value": "SE Asia Standard Time",
      "abbr": "SAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Bangkok, Hanoi, Jakarta",
      "utc": [
        "Antarctica/Davis",
        "Asia/Bangkok",
        "Asia/Hovd",
        "Asia/Jakarta",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Saigon",
        "Asia/Vientiane",
        "Etc/GMT-7",
        "Indian/Christmas"
      ]
    },
    {
      "value": "N. Central Asia Standard Time",
      "abbr": "NCAST",
      "offset": 7,
      "isdst": false,
      "text": "(UTC+07:00) Novosibirsk",
      "utc": [
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk"
      ]
    },
    {
      "value": "China Standard Time",
      "abbr": "CST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      "utc": [
        "Asia/Hong_Kong",
        "Asia/Macau",
        "Asia/Shanghai"
      ]
    },
    {
      "value": "North Asia Standard Time",
      "abbr": "NAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Krasnoyarsk",
      "utc": [
        "Asia/Krasnoyarsk"
      ]
    },
    {
      "value": "Singapore Standard Time",
      "abbr": "MPST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Kuala Lumpur, Singapore",
      "utc": [
        "Asia/Brunei",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Singapore",
        "Etc/GMT-8"
      ]
    },
    {
      "value": "W. Australia Standard Time",
      "abbr": "WAST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Perth",
      "utc": [
        "Antarctica/Casey",
        "Australia/Perth"
      ]
    },
    {
      "value": "Taipei Standard Time",
      "abbr": "TST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Taipei",
      "utc": [
        "Asia/Taipei"
      ]
    },
    {
      "value": "Ulaanbaatar Standard Time",
      "abbr": "UST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Ulaanbaatar",
      "utc": [
        "Asia/Choibalsan",
        "Asia/Ulaanbaatar"
      ]
    },
    {
      "value": "North Asia East Standard Time",
      "abbr": "NAEST",
      "offset": 8,
      "isdst": false,
      "text": "(UTC+08:00) Irkutsk",
      "utc": [
        "Asia/Irkutsk"
      ]
    },
    {
      "value": "Japan Standard Time",
      "abbr": "JST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Osaka, Sapporo, Tokyo",
      "utc": [
        "Asia/Dili",
        "Asia/Jayapura",
        "Asia/Tokyo",
        "Etc/GMT-9",
        "Pacific/Palau"
      ]
    },
    {
      "value": "Korea Standard Time",
      "abbr": "KST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Seoul",
      "utc": [
        "Asia/Pyongyang",
        "Asia/Seoul"
      ]
    },
    {
      "value": "Cen. Australia Standard Time",
      "abbr": "CAST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Adelaide",
      "utc": [
        "Australia/Adelaide",
        "Australia/Broken_Hill"
      ]
    },
    {
      "value": "AUS Central Standard Time",
      "abbr": "ACST",
      "offset": 9.5,
      "isdst": false,
      "text": "(UTC+09:30) Darwin",
      "utc": [
        "Australia/Darwin"
      ]
    },
    {
      "value": "E. Australia Standard Time",
      "abbr": "EAST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Brisbane",
      "utc": [
        "Australia/Brisbane",
        "Australia/Lindeman"
      ]
    },
    {
      "value": "AUS Eastern Standard Time",
      "abbr": "AEST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Canberra, Melbourne, Sydney",
      "utc": [
        "Australia/Melbourne",
        "Australia/Sydney"
      ]
    },
    {
      "value": "West Pacific Standard Time",
      "abbr": "WPST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Guam, Port Moresby",
      "utc": [
        "Antarctica/DumontDUrville",
        "Etc/GMT-10",
        "Pacific/Guam",
        "Pacific/Port_Moresby",
        "Pacific/Saipan",
        "Pacific/Truk"
      ]
    },
    {
      "value": "Tasmania Standard Time",
      "abbr": "TST",
      "offset": 10,
      "isdst": false,
      "text": "(UTC+10:00) Hobart",
      "utc": [
        "Australia/Currie",
        "Australia/Hobart"
      ]
    },
    {
      "value": "Yakutsk Standard Time",
      "abbr": "YST",
      "offset": 9,
      "isdst": false,
      "text": "(UTC+09:00) Yakutsk",
      "utc": [
        "Asia/Chita",
        "Asia/Khandyga",
        "Asia/Yakutsk"
      ]
    },
    {
      "value": "Central Pacific Standard Time",
      "abbr": "CPST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Solomon Is., New Caledonia",
      "utc": [
        "Antarctica/Macquarie",
        "Etc/GMT-11",
        "Pacific/Efate",
        "Pacific/Guadalcanal",
        "Pacific/Kosrae",
        "Pacific/Noumea",
        "Pacific/Ponape"
      ]
    },
    {
      "value": "Vladivostok Standard Time",
      "abbr": "VST",
      "offset": 11,
      "isdst": false,
      "text": "(UTC+11:00) Vladivostok",
      "utc": [
        "Asia/Sakhalin",
        "Asia/Ust-Nera",
        "Asia/Vladivostok"
      ]
    },
    {
      "value": "New Zealand Standard Time",
      "abbr": "NZST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Auckland, Wellington",
      "utc": [
        "Antarctica/McMurdo",
        "Pacific/Auckland"
      ]
    },
    {
      "value": "UTC+12",
      "abbr": "U",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Coordinated Universal Time+12",
      "utc": [
        "Etc/GMT-12",
        "Pacific/Funafuti",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Nauru",
        "Pacific/Tarawa",
        "Pacific/Wake",
        "Pacific/Wallis"
      ]
    },
    {
      "value": "Fiji Standard Time",
      "abbr": "FST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Fiji",
      "utc": [
        "Pacific/Fiji"
      ]
    },
    {
      "value": "Magadan Standard Time",
      "abbr": "MST",
      "offset": 12,
      "isdst": false,
      "text": "(UTC+12:00) Magadan",
      "utc": [
        "Asia/Anadyr",
        "Asia/Kamchatka",
        "Asia/Magadan",
        "Asia/Srednekolymsk"
      ]
    },
    {
      "value": "Kamchatka Standard Time",
      "abbr": "KDT",
      "offset": 13,
      "isdst": true,
      "text": "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
      "utc": [
        "Asia/Kamchatka"
      ]
    },
    {
      "value": "Tonga Standard Time",
      "abbr": "TST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Nuku'alofa",
      "utc": [
        "Etc/GMT-13",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Tongatapu"
      ]
    },
    {
      "value": "Samoa Standard Time",
      "abbr": "SST",
      "offset": 13,
      "isdst": false,
      "text": "(UTC+13:00) Samoa",
      "utc": [
        "Pacific/Apia"
      ]
    }
  ]
  store_hour_data: any;
 
  serializedDate:any='';
  date_value:any='';

  myControl: FormControl = new FormControl('');
  constructor( private platform:Platform, private renderer: Renderer2, private router:Router, private modalCtrl:ModalController, private apiservice:ApiService, private navParam:NavParams, public alertCtrl:AlertController) { 
 
    this.storedata=this.navParam.get('data');
  this.today=moment(new Date()).format('mm-DD-YYYY');
  let date = new FormControl(new Date());
  this.serializedDate = new FormControl((new Date()).toISOString());
  if(this.storedata!=undefined){
  this.name=this.storedata.store_name.trim(); 
  this.address=this.storedata.store_address;
  this.phone=this.storedata.phone_number;
  this.store_id=this.storedata.store_id;
  this.get_storeinfo();
  this.get_holidays_api('true');
  this.get_holiday_data();
  }

  this.renderer.listen('window', 'click', (e: Event) => {
    
  })
  }
  date:any;
  someMethodName(ev){
    
   //  this.holiday_date=moment(this.serializedDate.value).format('YYYY-MM-DD');
     //(this.date);
     this.holiday_date=moment(this.date).format('MM/DD/YYYY');
  }

  
  onToggle1(ev){
    this.process=false;
    this.complete=false;
    this.monday_validtime=false;
    if(!this.open_close1){
      this.open_date1='09:00 AM';
      this.close_date1='09:00 PM';
      this.sel_open_date1='09:00 AM';
      this.sel_close_date1='09:00 PM';
    }else{
      this.open_date1='';
      this.close_date1='';
    }
   
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }

  onToggle2(ev){
    this.process=false;
    this.complete=false;
    this.tuesday_validtime=false;
    if(!this.open_close2){
      this.open_date2='09:00 AM';
      this.close_date2='09:00 PM';
      this.sel_open_date2='09:00 AM';
      this.sel_close_date2='09:00 PM';
    }else{
      this.open_date2='';
      this.close_date2='';
    }
   
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }
  onToggle3(ev){
    this.process=false;
    this.complete=false;
    this.wednesday_validtime=false;
    if(!this.open_close3){
      this.open_date3='09:00 AM';
      this.close_date3='09:00 PM';
      this.sel_open_date3='09:00 AM';
      this.sel_close_date3='09:00 PM';
    }else{
      this.open_date3='';
      this.close_date3='';
    }
  
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }
  onToggle4(ev){
    this.process=false;
    this.complete=false;
    this.thursday_validtime=false;
    if(!this.open_close4){
      this.open_date4='09:00 AM';
      this.close_date4='09:00 PM';
      this.sel_open_date4='09:00 AM';
      this.sel_close_date4='09:00 PM';
    }else{
      this.open_date4='';
      this.close_date4='';
    }
    
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }
  onToggle5(ev){
    this.process=false;
    this.complete=false;
    this.friday_validtime=false;
    if(!this.open_close5){
      this.open_date5='09:00 AM';
      this.close_date5='09:00 PM';
      this.sel_open_date5='09:00 AM';
      this.sel_close_date5='09:00 PM';
    }else{
      this.open_date5='';
      this.close_date5='';
    }
    
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }
  onToggle6(ev){
    this.process=false;
    this.complete=false;
    this.saturday_validtime=false;
    if(!this.open_close6){
      this.open_date6='09:00 AM';
      this.close_date6='09:00 PM';
      this.sel_open_date6='09:00 AM';
      this.sel_close_date6='09:00 PM';
    }else{
      this.open_date6='';
      this.close_date6='';
    }
   
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }
  onToggle7(ev){
    this.process=false;
    this.complete=false;
    this.sunday_validtime=false;
    if(!this.open_close7){
      this.open_date7='09:00 AM';
      this.close_date7='09:00 PM';
      this.sel_open_date7='09:00 AM';
      this.sel_close_date7='09:00 PM';
    }else{
      this.open_date7='';
      this.close_date7='';
    }
    
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
  }

  add1_hh(){    
    if(this.hour1<12){
      let t=parseInt(this.hour1)+1;
      if(t<=9){
        this.hour1='0'+t;
      }else{
        this.hour1=t;
      }
    this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }else{
      this.hour1='01';
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }
  }

  down1_hh(){
    if(this.hour1>1){
      let t=parseInt(this.hour1)-1;
      if(t<=9){
        this.hour1='0'+t;
      }else{
        this.hour1=t;
      }
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
      }else{
        this.hour1=12;
        this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
      }
  }

  add1_mm(){
    if(this.minutes1<59){
      let t=parseInt(this.minutes1)+1;
      if(t<=9){
        this.minutes1='0'+t;
      }else{
        this.minutes1=t;
      }
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }else if(this.minutes1==59){
      this.minutes1='00';
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1
    }else{
      this.minutes1='01';
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }
  }

  down1_mm(){
    if(this.minutes1>=1){
      let t=parseInt(this.minutes1)-1;
      if(t<=9){
        this.minutes1='0'+t;
      }else{
        this.minutes1=t;
      }
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
      }else{
        this.minutes1=59;
        this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
      }
  }
  time1(){
    if(this.t1=='AM'){
      this.t1='PM';
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }else{
      this.t1='AM';
      this.open_date1=this.hour1+':'+this.minutes1+' '+this.t1;
    }
  }
  add11_hh(){
    if(this.hour11<12){
      let t=parseInt(this.hour11)+1;
      if(t<=9){
        this.hour11='0'+t;
      }else{
        this.hour11=t;
      }
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }else{
      this.hour11='01';
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }
  }
  down11_hh(){
    if(this.hour11>=1){
      let t=parseInt(this.hour11)-1;
      if(t<=9){
        this.hour11='0'+t;
      }else{
        this.hour11=t;
      }
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
      }else{
        this.hour11=11;
        this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
      }
  }

  add11_mm(){
    if(this.minutes11<59){
      let t=parseInt(this.minutes11)+1;
      if(t<=9){
        this.minutes11='0'+t;
      }else{
        this.minutes11=t;
      }
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }else if(this.minutes11==59){
      this.minutes11='00';
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }else{
      this.minutes11='01';
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }
  }

  down11_mm(){
    if(this.minutes11>=1){
      let t=parseInt(this.minutes11)-1;
      if(t<=9){
        this.minutes11='0'+t;
      }else{
        this.minutes11=t;
      }
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
      }else{
        this.minutes11=59;
        this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
      }
  }

  time11(){
    if(this.t11=='AM'){
      this.t11='PM';
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }else{
      this.t11='AM';
      this.close_date1=this.hour11+':'+this.minutes11+' '+this.t11;
    }
  }


//second timer
  add2_hh(){
    
    if(this.hour2<12){
      let t=parseInt(this.hour2)+1;
      if(t<=9){
        this.hour2='0'+t;
      }else{
        this.hour2=t;
      }
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }else{
      this.hour2='01';
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }
  }

  down2_hh(){
    if(this.hour2>=1){
      let t=parseInt(this.hour2)-1;
      if(t<=9){
        this.hour2='0'+t;
      }else{
        this.hour2=t;
      }
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
      }else{
        this.hour2=11;
        this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
      }
  }

  add2_mm(){
    if(this.minutes2<60){
      let t=parseInt(this.minutes2)+1;
      if(t<=9){
        this.minutes2='0'+t;
      }else{
        this.minutes2=t;
      }
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }else if(this.minutes2==59){
      this.minutes2='00';
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2; 
    }else{
      this.minutes2='01';
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }
  }

  down2_mm(){
    if(this.minutes2>=1){
      let t=parseInt(this.minutes2)-1;
      if(t<=9){
        this.minutes2='0'+t;
      }else{
        this.minutes2=t;
      }
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
      }else{
        this.minutes2=59;
        this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
      }
  }

  time2(){
    if(this.t2=='AM'){
      this.t2='PM';
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }else{
      this.t2='AM';
      this.open_date2=this.hour2+':'+this.minutes2+' '+this.t2;
    }
  }

  add22_hh(){
    
    if(this.hour22<12){
      let t=parseInt(this.hour22)+1;
      if(t<=9){
        this.hour22='0'+t;
      }else{
        this.hour22=t;
      }
    this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }else{
      this.hour22='01';
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }
  }
  down22_hh(){
    if(this.hour22>1){
      let t=parseInt(this.hour22)-1;
      if(t<=9){
        this.hour22='0'+t;
      }else{
        this.hour22=t;
      }
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
      }else{
        this.hour22=11;
        this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
      }
  }
  add22_mm(){
    if(this.minutes22<60){
      let t=parseInt(this.minutes22)+1;
      if(t<=9){
        this.minutes22='0'+t;
      }else{
        this.minutes22=t;
      }
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }else if(this.minutes22==59){
      this.minutes22='00';
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }else{
      this.minutes22='01';
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }
  }

  down22_mm(){
    if(this.minutes22>=1){
      let t=parseInt(this.minutes22)-1;
      if(t<=9){
        this.minutes22='0'+t;
      }else{
        this.minutes22=t;
      }
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
      }else{
        this.minutes22=59;
        this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
      }
  }

  time22(){
    if(this.t22=='AM'){
      this.t22='PM';
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }else{
      this.t22='AM';
      this.close_date2=this.hour22+':'+this.minutes22+' '+this.t22;
    }
  }

  //third timer
  add3_hh(){
    
    if(this.hour3<12){
      let t=parseInt(this.hour3)+1;
      if(t<=9){
        this.hour3='0'+t;
      }else{
        this.hour3=t;
      }
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }else{
      this.hour3='01';
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }
  }

  down3_hh(){
    if(this.hour3>=1){
      let t=parseInt(this.hour3)-1;
      if(t<=9){
        this.hour3='0'+t;
      }else{
        this.hour3=t;
      }
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
      }else{
        this.hour3=11;
        this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
      }
  }

  add3_mm(){
    if(this.minutes3<60){
      let t=parseInt(this.minutes3)+1;
      if(t<=9){
        this.minutes3='0'+t;
      }else{
        this.minutes3=t;
      }
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }else if(this.minutes3==59){
      this.minutes3='00';
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }else{
      this.minutes3='01';
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }
  }

  down3_mm(){
    if(this.minutes3>1){
      let t=parseInt(this.minutes3)-1;
      if(t<=9){
        this.minutes3='0'+t;
      }else{
        this.minutes3=t;
      }
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
      }else{
        this.minutes3=59;
        this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
      }
  }

  time3(){
    if(this.t3=='AM'){
      this.t3='PM';
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }else{
      this.t3='AM';
      this.open_date3=this.hour3+':'+this.minutes3+' '+this.t3;
    }
  }

  add33_hh(){
    if(this.hour33<12){
      let t=parseInt(this.hour33)+1;
      if(t<=9){
        this.hour33='0'+t;
      }else{
        this.hour33=t;
      }
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }else{
      this.hour33='01';
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }
  }
  down33_hh(){
    if(this.hour33>=1){
      let t=parseInt(this.hour33)-1;
      if(t<=9){
        this.hour33='0'+t;
      }else{
        this.hour33=t;
      }
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
      }else{
        this.hour33=11;
        this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
      }
  }
  add33_mm(){
    
    if(this.minutes33<60){
      let t=parseInt(this.minutes33)+1;
      if(t<=9){
        this.minutes33='0'+t;
      }else{
        this.minutes33=t;
      }
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }else{
      this.minutes33='01';
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }
  }
  down33_mm(){
    if(this.minutes33>1){
      let t=parseInt(this.minutes33)-1;
      if(t<=9){
        this.minutes33='0'+t;
      }else{
        this.minutes33=t;
      }
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
      }else{
        this.minutes33=59;
        this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
      }
  }

  time33(){
    if(this.t33=='AM'){
      this.t33='PM';
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }else{
      this.t33='AM';
      this.close_date3=this.hour33+':'+this.minutes33+' '+this.t33;
    }
  }

    //fourth timer
    add4_hh(){
      
      if(this.hour4<12){
        let t=parseInt(this.hour4)+1;
        if(t<=9){
          this.hour4='0'+t;
        }else{
          this.hour4=t;
        }
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }else{
        this.hour4='01';
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }
    }
  
    down4_hh(){
      if(this.hour4>1){
        let t=parseInt(this.hour4)-1;
        if(t<=9){
          this.hour4='0'+t;
        }else{
          this.hour4=t;
        }
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
        }else{
          this.hour4=11;
          this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
        }
    }
  
    add4_mm(){
      
      if(this.minutes4<60){
        let t=parseInt(this.minutes4)+1;
        if(t<=9){
          this.minutes4='0'+t;
        }else{
          this.minutes4=t;
        }
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }else{
        this.minutes4='01';
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }
    }
  
    down4_mm(){
      if(this.minutes4>1){
        let t=parseInt(this.minutes4)-1;
        if(t<=9){
          this.minutes4='0'+t;
        }else{
          this.minutes4=t;
        }
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
        }else{
          this.minutes4=59;
          this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
        }
    }
  
    time4(){
      if(this.t4=='AM'){
        this.t4='PM';
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }else{
        this.t4='AM';
        this.open_date4=this.hour4+':'+this.minutes4+' '+this.t4;
      }
    }

    add44_hh(){
      
      if(this.hour44<12){
        let t=parseInt(this.hour44)+1;
        if(t<=9){
          this.hour44='0'+t;
        }else{
          this.hour44=t;
        }
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }else{
        this.hour44='01';
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }
    }

    down44_hh(){
      if(this.hour44>1){
        let t=parseInt(this.hour44)-1;
        if(t<=9){
          this.hour44='0'+t;
        }else{
          this.hour44=t;
        }
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
        }else{
          this.hour44=11;
          this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
        }
    }
    add44_mm(){
      
      if(this.minutes44<60){
        let t=parseInt(this.minutes44)+1;
        if(t<=9){
          this.minutes44='0'+t;
        }else{
          this.minutes44=t;
        }
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }else{
        this.minutes44='01';
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }
    }
    down44_mm(){
      if(this.minutes44>1){
        let t=parseInt(this.minutes44)-1;
        if(t<=9){
          this.minutes44='0'+t;
        }else{
          this.minutes44=t;
        }
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
        }else{
          this.minutes44=59;
          this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
        }
    }
  
    time44(){
      if(this.t44=='AM'){
        this.t44='PM';
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }else{
        this.t44='AM';
        this.close_date4=this.hour44+':'+this.minutes44+' '+this.t44;
      }
    }

    //fifth timer
  add5_hh(){
    
    if(this.hour5<12){
      let t=parseInt(this.hour5)+1;
      if(t<=9){
        this.hour5='0'+t;
      }else{
        this.hour5=t;
      }
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }else{
      this.hour5='01';
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }
  }

  down5_hh(){
    if(this.hour5>1){
      let t=parseInt(this.hour5)-1;
      if(t<=9){
        this.hour5='0'+t;
      }else{
        this.hour5=t;
      }
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
      }else{
        this.hour5=11;
        this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
      }
  }

  add5_mm(){
    
    if(this.minutes5<60){
      let t=parseInt(this.minutes5)+1;
      if(t<=9){
        this.minutes5='0'+t;
      }else{
        this.minutes5=t;
      }
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }else{
      this.minutes5='01';
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }
  }

  down5_mm(){
    if(this.minutes5>1){
      let t=parseInt(this.minutes5)-1;
      if(t<=9){
        this.minutes5='0'+t;
      }else{
        this.minutes5=t;
      }
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
      }else{
        this.minutes5=59;
        this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
      }
  }

  time5(){
    if(this.t5=='AM'){
      this.t5='PM';
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }else{
      this.t5='AM';
      this.open_date5=this.hour5+':'+this.minutes5+' '+this.t5;
    }
  }
  add55_hh(){
    
    if(this.hour55<12){
      let t=parseInt(this.hour55)+1;
      if(t<=9){
        this.hour55='0'+t;
      }else{
        this.hour55=t;
      }
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }else{
      this.hour55='01';
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }
  }

  down55_hh(){
    if(this.hour55>1){
      let t=parseInt(this.hour55)-1;
      if(t<=9){
        this.hour55='0'+t;
      }else{
        this.hour55=t;
      }
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
      }else{
        this.hour55=11;
        this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
      }
  }
  add55_mm(){
    
    if(this.minutes55<60){
      let t=parseInt(this.minutes55)+1;
      if(t<=9){
        this.minutes55='0'+t;
      }else{
        this.minutes55=t;
      }
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }else{
      this.minutes55='01';
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }
  }
  down55_mm(){
    if(this.minutes55>1){
      let t=parseInt(this.minutes55)-1;
      if(t<=9){
        this.minutes55='0'+t;
      }else{
        this.minutes55=t;
      }
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
      }else{
        this.minutes55=59;
        this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
      }
  }

  time55(){
    if(this.t55=='AM'){
      this.t55='PM';
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }else{
      this.t55='AM';
      this.close_date5=this.hour55+':'+this.minutes55+' '+this.t55;
    }
  }

  //sixth time
    add6_hh(){
      
      if(this.hour6<12){
        let t=parseInt(this.hour6)+1;
        if(t<=9){
          this.hour6='0'+t;
        }else{
          this.hour6=t;
        }
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }else{
        this.hour6='01';
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }
    }
  
    down6_hh(){
      if(this.hour6>1){
        let t=parseInt(this.hour6)-1;
        if(t<=9){
          this.hour6='0'+t;
        }else{
          this.hour6=t;
        }
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
        }else{
          this.hour6=11;
          this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
        }
    }
  
    add6_mm(){
      
      if(this.minutes6<60){
        let t=parseInt(this.minutes6)+1;
        if(t<=9){
          this.minutes6='0'+t;
        }else{
          this.minutes6=t;
        }
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }else{
        this.minutes6='01';
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }
    }
  
    down6_mm(){
      if(this.minutes6>1){
        let t=parseInt(this.minutes6)-1;
        if(t<=9){
          this.minutes6='0'+t;
        }else{
          this.minutes6=t;
        }
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
        }else{
          this.minutes6=59;
          this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
        }
    }
  
    time6(){
      if(this.t6=='AM'){
        this.t6='PM';
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }else{
        this.t6='AM';
        this.open_date6=this.hour6+':'+this.minutes6+' '+this.t6;
      }
    }
    add66_hh(){
      
      if(this.hour66<12){
        let t=parseInt(this.hour66)+1;
        if(t<=9){
          this.hour66='0'+t;
        }else{
          this.hour66=t;
        }
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }else{
        this.hour66='01';
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }
    }
    down66_hh(){
      if(this.hour66>1){
        let t=parseInt(this.hour66)-1;
        if(t<=9){
          this.hour66='0'+t;
        }else{
          this.hour66=t;
        }
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
        }else{
          this.hour66=11;
          this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
        }
    }
    add66_mm(){
      
      if(this.minutes66<60){
        let t=parseInt(this.minutes66)+1;
        if(t<=9){
          this.minutes66='0'+t;
        }else{
          this.minutes66=t;
        }
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }else{
        this.minutes66='01';
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }
    }
    down66_mm(){
      if(this.minutes66>1){
        let t=parseInt(this.minutes66)-1;
        if(t<=9){
          this.minutes66='0'+t;
        }else{
          this.minutes66=t;
        }
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
        }else{
          this.minutes66=59;
          this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
        }
    }
  
    time66(){
      if(this.t66=='AM'){
        this.t66='PM';
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }else{
        this.t66='AM';
        this.close_date6=this.hour66+':'+this.minutes66+' '+this.t66;
      }
    }


    //seventh timer
  add7_hh(){
    
    if(this.hour7<12){
      let t=parseInt(this.hour7)+1;
      if(t<=9){
        this.hour7='0'+t;
      }else{
        this.hour7=t;
      }
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }else{
      this.hour7='01';
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }
  }

  down7_hh(){
    if(this.hour7>1){
      let t=parseInt(this.hour7)-1;
      if(t<=9){
        this.hour7='0'+t;
      }else{
        this.hour7=t;
      }
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
      }else{
        this.hour7=11;
        this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
      }
  }

  add7_mm(){
    
    if(this.minutes7<60){
      let t=parseInt(this.minutes7)+1;
      if(t<=9){
        this.minutes7='0'+t;
      }else{
        this.minutes7=t;
      }
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }else{
      this.minutes7='01';
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }
  }

  down7_mm(){
    if(this.minutes7>1){
      let t=parseInt(this.minutes7)-1;
      if(t<=9){
        this.minutes7='0'+t;
      }else{
        this.minutes7=t;
      }
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
      }else{
        this.minutes7=59;
        this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
      }
  }

  time7(){
    if(this.t7=='AM'){
      this.t7='PM';
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }else{
      this.t7='AM';
      this.open_date7=this.hour7+':'+this.minutes7+' '+this.t7;
    }
  }
  add77_hh(){
    
    if(this.hour77<12){
      let t=parseInt(this.hour77)+1;
      if(t<=9){
        this.hour77='0'+t;
      }else{
        this.hour77=t;
      }
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }else{
      this.hour77='01';
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }
  }

  down77_hh(){
    if(this.hour77>1){
      let t=parseInt(this.hour77)-1;
      if(t<=9){
        this.hour77='0'+t;
      }else{
        this.hour77=t;
      }
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
      }else{
        this.hour77=11;
        this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
      }
  }
  add77_mm(){
    
    if(this.minutes77<60){
      let t=parseInt(this.minutes77)+1;
      if(t<=9){
        this.minutes77='0'+t;
      }else{
        this.minutes77=t;
      }
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }else{
      this.minutes77='01';
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }
  }
  down77_mm(){
    if(this.minutes77>1){
      let t=parseInt(this.minutes77)-1;
      if(t<=9){
        this.minutes77='0'+t;
      }else{
        this.minutes77=t;
      }
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
      }else{
        this.minutes77=59;
        this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
      }
  }

  time77(){
    if(this.t77=='AM'){
      this.t77='PM';
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }else{
      this.t77='AM';
      this.close_date7=this.hour77+':'+this.minutes77+' '+this.t77;
    }
  }

  date_click1(){

    if(this.open_close1){
      this.timer_div1=!this.timer_div1;
      }

      if(this.timer_div1){
        if(this.open_date1 != ''){
        let d=this.open_date1.split(':');
        this.hour1=d[0];
        let t=d[1].split(' ');
        this.minutes1=t[0];
        this.t1=t[1];
        }else{
          this.hour1='09';
          this.minutes1='00';
          this.t1='AM'
        }
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }


      //updating the dates on closing the timer
      if(this.open_close1){
      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid2){
        this.open_date2=this.sel_open_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
        if( t==null || t1==true ){
          this.open_date2=this.sel_open_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid4){
        this.open_date4=this.sel_open_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
        if( t==null || t1==true ){
          this.open_date4=this.sel_open_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }
    }
      
    if(this.open_close2){
      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid44){
        this.close_date4=this.sel_close_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
        if( t==null || t1==true ){
          this.close_date4=this.sel_close_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }
  }

  date_click11(){
   

    if(this.open_close1){
      this.timer_div11=!this.timer_div11;
    }
    if(this.timer_div11){
      if(this.close_date1 !=''){
      let d=this.close_date1.split(':');
      this.hour11=d[0];
      let t=d[1].split(' ');
      this.minutes11=t[0];
      this.t11=t[1];
      }else{
        this.hour11='09';
        this.minutes11='00';
        this.t11='PM'
      }
      
    }

    if(this.open_close1){
    if(!this.valid1){
      this.open_date1=this.sel_open_date1;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date1.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
      if( t==null || t1==true ){
        this.open_date1=this.sel_open_date1;
      }
    }
  }

  if(this.open_close2){
    if(!this.valid2){
      this.open_date2=this.sel_open_date2;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date2.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
      if( t==null || t1==true ){
        this.open_date2=this.sel_open_date2;
      }
    }
  }

  if(this.open_close3){
    if(!this.valid3){
      this.open_date3=this.sel_open_date3;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date3.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
      if( t==null || t1==true ){
        this.open_date3=this.sel_open_date3;
      }
    }
  }

  if(this.open_close4){
    if(!this.valid4){
      this.open_date4=this.sel_open_date4;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date4.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
      if( t==null || t1==true ){
        this.open_date4=this.sel_open_date4;
      }
    }
  }

  if(this.open_close5){
    if(!this.valid5){
      this.open_date5=this.sel_open_date5;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date5.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
      if( t==null || t1==true ){
        this.open_date5=this.sel_open_date5;
      }
    }
  }

  if(this.open_close6){
    if(!this.valid6){
      this.open_date6=this.sel_open_date6;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date6.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
      if( t==null || t1==true ){
        this.open_date6=this.sel_open_date6;
      }
    }
  }

  if(this.open_close7){
    if(!this.valid7){
      this.open_date7=this.sel_open_date7;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.open_date7.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
      if( t==null || t1==true ){
        this.open_date7=this.sel_open_date7;
      }
    }
  }


    if(this.open_close2){
    if(!this.valid22){
      this.close_date2=this.sel_close_date2;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date2.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
      if( t==null || t1==true ){
        this.close_date2=this.sel_close_date2;
      }
    }
  }

  if(this.open_close3){
    if(!this.valid33){
      this.close_date3=this.sel_close_date3;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date3.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
      if( t==null || t1==true ){
        this.close_date3=this.sel_close_date3;
      }
    }
  }

  if(this.open_close4){
    if(!this.valid44){
      this.close_date4=this.sel_close_date4;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date4.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
      if( t==null || t1==true ){
        this.close_date4=this.sel_close_date4;
      }
    }
  }

  if(this.open_close5){
    if(!this.valid55){
      this.close_date5=this.sel_close_date5;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date5.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
      if( t==null || t1==true ){
        this.close_date5=this.sel_close_date5;
      }
    }
  }

  if(this.open_close6){
    if(!this.valid66){
      this.close_date6=this.sel_close_date6;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date6.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
      if( t==null || t1==true ){
        this.close_date6=this.sel_close_date6;
      }
    }
  }

  if(this.open_close7){
    if(!this.valid77){
      this.close_date7=this.sel_close_date7;
    }else{
      let reWhiteSpace = /\s/g;
      let t=this.close_date7.match(reWhiteSpace);
     let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
      if( t==null || t1==true ){
        this.close_date7=this.sel_close_date7;
      }
    }
  }


   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }
  }

  date_click2(){
    
    if(this.open_close2){
      this.timer_div2=!this.timer_div2;
    }
    if(this.timer_div2){
      if(this.open_date2 !=''){
      let d=this.open_date2.split(':');
      this.hour2=d[0];
      let t=d[1].split(' ');
      this.minutes2=t[0];
      this.t2=t[1];
      }else{
        this.hour2='09';
        this.minutes2='00';
        this.t2='AM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
     
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

      //update date on closing the timer
      if(this.sel_open_close1){
      if(!this.valid1){
        this.open_date1=this.sel_open_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
        if( t==null || t1==true ){
          this.open_date1=this.sel_open_date1;
        }
      }
    }
    
    if(this.sel_open_close3){
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid4){
        this.open_date4=this.sel_open_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
        if( t==null || t1==true ){
          this.open_date4=this.sel_open_date4;
        }
      }
    }
    
    if(this.open_close5){
      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }
    }

    if(this.open_close1){
      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
         let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid44){
        this.close_date4=this.sel_close_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
        if( t==null || t1==true ){
          this.close_date4=this.sel_close_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }
  }

  date_click22(){
    if(this.open_close2){
      this.timer_div22=!this.timer_div22;
    }
    if(this.timer_div22){
      if(this.close_date2 != ''){
      let d=this.close_date2.split(':');
      this.hour22=d[0];
      let t=d[1].split(' ');
      this.minutes22=t[0];
      this.t22=t[1];
      }else{
        this.hour2='09';
        this.minutes2='00';
        this.t2='PM';
      }
    }
  
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
     
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

       //update date on closing the timer
       if(this.open_close1){
       if(!this.valid1){
        this.open_date1=this.sel_open_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
        if( t==null || t1==true ){
          this.open_date1=this.sel_open_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid2){
        this.open_date2=this.sel_open_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
        if( t==null || t1==true ){
          this.open_date2=this.sel_open_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid4){
        this.open_date4=this.sel_open_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
        if( t==null || t1==true ){
          this.open_date4=this.sel_open_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }
    }

    if(this.open_close1){
      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid44){
        this.close_date4=this.sel_close_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
        if( t==null || t1==true ){
          this.close_date4=this.sel_close_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }
  }
  date_click3(){
   
    if(this.open_close3){
      this.timer_div3=!this.timer_div3;
    }

    if(this.timer_div3){
      if(this.open_date3 != ''){
      let d=this.open_date3.split(':');
      this.hour3=d[0];
      let t=d[1].split(' ');
      this.minutes3=t[0];
      this.t3=t[1];
      }else{
        this.hour22='09';
        this.minutes22='00';
        this.t22='AM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
     
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }
       
      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }
  date_click33(){
   
    if(this.open_close3){
      this.timer_div33=!this.timer_div33;
    }
    if(this.timer_div33){
      if(this.close_date3 != ''){
      let d=this.close_date3.split(':');
      this.hour33=d[0];
      let t=d[1].split(' ');
      this.minutes33=t[0];
      this.t33=t[1];
      }else{
        this.hour33='09';
        this.minutes33='00';
        this.t33='PM';
      }
    }
    
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
     
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }
      
      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }
  date_click4(){
   
    if(this.open_close4){
      this.timer_div4=!this.timer_div4;
    }
   
    if(this.timer_div4){
      if(this.open_date4 != ''){
      let d=this.open_date4.split(':');
      this.hour4=d[0];
      let t=d[1].split(' ');
      this.minutes4=t[0];
      this.t4=t[1];
      }else{
        this.hour4='09';
        this.minutes4='00';
        this.t4='AM';
      }
    }
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
     
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

       //update date on closing the timer
       if(this.open_close1){
       if(!this.valid1){
        this.open_date1=this.sel_open_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
        if( t==null || t1==true ){
          this.open_date1=this.sel_open_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid2){
        this.open_date2=this.sel_open_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
        if( t==null || t1==true ){
          this.open_date2=this.sel_open_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }
    }

     if(this.open_close5){ 
      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }
    }

    if(this.open_close1){
      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }
    }
    
    if(this.open_close4){
      if(!this.valid44){
        this.close_date4=this.sel_close_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
        if( t==null || t1==true ){
          this.close_date4=this.sel_close_date4;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }
  }
  date_click44(){
   
    if(this.open_close4){
      this.timer_div44=!this.timer_div44;
    }

    if(this.timer_div44){
      if(this.close_date4!=''){
      let d=this.close_date4.split(':');
      this.hour44=d[0];
      let t=d[1].split(' ');
      this.minutes44=t[0];
      this.t44=t[1];
      }else{
        this.hour44='09';
        this.minutes44='00';
        this.t44='PM';
      }
    }
  
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
     
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

       //update date on closing the timer
       if(this.open_close1){
       if(!this.valid1){
        this.open_date1=this.sel_open_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
        if( t==null || t1==true ){
          this.open_date1=this.sel_open_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid2){
        this.open_date2=this.sel_open_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
        if( t==null || t1==true ){
          this.open_date2=this.sel_open_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }
    }

    if(this.open_close4){
      if(!this.valid4){
        this.open_date4=this.sel_open_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
        if( t==null || t1==true ){
          this.open_date4=this.sel_open_date4;
        }
      }
    }
     
    if(this.open_close5){
      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }
    }

    if(this.open_close1){
      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }
    }

    if(this.open_close2){
      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }
    }

    if(this.open_close3){
      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }
    }

    if(this.open_close5){
      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }
    }

    if(this.open_close6){
      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }
    }

    if(this.open_close7){
      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }
  }
  date_click5(){
   
    if(this.open_close5){
      this.timer_div5=!this.timer_div5;
    }

    if(this.timer_div5){
      if(this.open_date5!=''){
      let d=this.open_date5.split(':');
      this.hour5=d[0];
      let t=d[1].split(' ');
      this.minutes5=t[0];
      this.t5=t[1];
      }else{
        this.hour5='09';
        this.minutes5='00';
        this.t5='AM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
     
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_close_date7;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }
      
      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
          if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }
      
      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }

  date_click55(){
    
    if(this.open_close5){
      this.timer_div55=!this.timer_div55;
    }

    if(this.timer_div55){
      if(this.close_date5!=''){
      let d=this.close_date5.split(':');
      this.hour55=d[0];
      let t=d[1].split(' ');
      this.minutes55=t[0];
      this.t55=t[1];
      }else{
        this.hour55='09';
        this.minutes55='00';
        this.t55='PM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
     
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }
     
      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }
  date_click6(){
   
    if(this.open_close6){
      this.timer_div6=!this.timer_div6;
    }

    if(this.timer_div6){
      if(this.open_date6 != ''){
      let d=this.open_date6.split(':');
      this.hour6=d[0];
      let t=d[1].split(' ');
      this.minutes6=t[0];
      this.t6=t[1];
      }else{
        this.hour6='09';
        this.minutes6='00';
        this.t6='AM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
     
      if(this.timer_div66){
      this.timer_div66=false;
      }
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }


      if(this.open_close7){  
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }
     
      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }

      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }
  date_click66(){
   
    if(this.open_close6){
      this.timer_div66=! this.timer_div66;
    }

    if(this.timer_div66){
      if(this.close_date6!=''){
      let d=this.close_date6.split(':');
      this.hour66=d[0];
      let t=d[1].split(' ');
      this.minutes66=t[0];
      this.t66=t[1];
      }else{
        this.hour66='09';
        this.minutes66='00';
        this.t66='PM';
      }
    }
  
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
     
      if(this.timer_div7){
      this.timer_div7=false;
      }
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }
       
      if(this.open_close7){
        if(!this.valid7){
          this.open_date7=this.sel_open_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
          if( t==null || t1==true ){
            this.open_date7=this.sel_open_date7;
          }
        }
      }

      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }
     
      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }
  date_click7(){
   
    if(this.open_close7){
      this.timer_div7=!this.timer_div7;
    }

    if(this.timer_div7){
      if(this.open_date7!=''){
      let d=this.open_date7.split(':');
      this.hour7=d[0];
      let t=d[1].split(' ');
      this.minutes7=t[0];
      this.t7=t[1];
      }else{
        this.hour7='09';
        this.minutes7='00';
        this.t7='AM';
      }
    }
   
    if(this.timer_div1){
      this.timer_div1=false;
      }
      if(this.timer_div11){
      this.timer_div11=false;
      }
      if(this.timer_div2){
      this.timer_div2=false;
      }
      if(this.timer_div22){
      this.timer_div22=false;
      }
      if(this.timer_div3){
      this.timer_div3=false;
      }
      if(this.timer_div33){
      this.timer_div33=false;
      }
      if(this.timer_div4){
      this.timer_div4=false;
      }
      if(this.timer_div44){
      this.timer_div44=false;
      }
      if(this.timer_div5){
      this.timer_div5=false;
      }
      if(this.timer_div55){
      this.timer_div55=false;
      }
      if(this.timer_div6){
      this.timer_div6=false;
      }
      if(this.timer_div66){
      this.timer_div66=false;
      }
     
      if(this.timer_div77){
      this.timer_div77=false;
      }

        //update date on closing the timer
        if(this.open_close1){
        if(!this.valid1){
          this.open_date1=this.sel_open_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
          if( t==null || t1==true ){
            this.open_date1=this.sel_open_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid2){
          this.open_date2=this.sel_open_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
          if( t==null || t1==true ){
            this.open_date2=this.sel_open_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid3){
          this.open_date3=this.sel_open_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
          if( t==null || t1==true ){
            this.open_date3=this.sel_open_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid4){
          this.open_date4=this.sel_open_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
          if( t==null || t1==true ){
            this.open_date4=this.sel_open_date4;
          }
        }
      }

      if(this.open_close5){
        if(!this.valid5){
          this.open_date5=this.sel_open_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
          if( t==null || t1==true ){
            this.open_date5=this.sel_open_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid6){
          this.open_date6=this.sel_open_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
          }
        }
      }
      
      if(this.open_close1){
        if(!this.valid11){
          this.close_date1=this.sel_close_date1;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date1.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
          if( t==null || t1==true ){
            this.close_date1=this.sel_close_date1;
          }
        }
      }

      if(this.open_close2){
        if(!this.valid22){
          this.close_date2=this.sel_close_date2;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
          if( t==null || t1==true ){
            this.close_date2=this.sel_close_date2;
          }
        }
      }

      if(this.open_close3){
        if(!this.valid33){
          this.close_date3=this.sel_close_date3;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
          }
        }
      }

      if(this.open_close4){
        if(!this.valid44){
          this.close_date4=this.sel_close_date4;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
          }
        }
      }
      
      if(this.open_close5){
        if(!this.valid55){
          this.close_date5=this.sel_close_date5;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date5.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
          if( t==null || t1==true ){
            this.close_date5=this.sel_close_date5;
          }
        }
      }

      if(this.open_close6){
        if(!this.valid66){
          this.close_date6=this.sel_close_date6;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
          if( t==null || t1==true ){
            this.close_date6=this.sel_close_date6;
          }
        }
      }
     
      if(this.open_close7){
        if(!this.valid77){
          this.close_date7=this.sel_close_date7;
        }else{
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
          }
        }
      }
  }

  date_click77(){
    
if(this.open_close7){
this.timer_div77=!this.timer_div77;
}
if(this.timer_div77){
  if(this.close_date7 != ''){
  let d=this.close_date7.split(':');
  this.hour77=d[0];
  let t=d[1].split(' ');
  this.minutes77=t[0];
  this.t77=t[1];
  }else{
    this.hour77='09';
    this.minutes77='00';
    this.t77='PM';
  }
}

if(this.timer_div1){
this.timer_div1=false;
}
if(this.timer_div11){
this.timer_div11=false;
}
if(this.timer_div2){
this.timer_div2=false;
}
if(this.timer_div22){
this.timer_div22=false;
}
if(this.timer_div3){
this.timer_div3=false;
}
if(this.timer_div33){
this.timer_div33=false;
}
if(this.timer_div4){
this.timer_div4=false;
}
if(this.timer_div44){
this.timer_div44=false;
}
if(this.timer_div5){
this.timer_div5=false;
}
if(this.timer_div55){
this.timer_div55=false;
}
if(this.timer_div6){
this.timer_div6=false;
}
if(this.timer_div66){
this.timer_div66=false;
}
if(this.timer_div7){
this.timer_div7=false;
}

  //update date on closing the timer
  if(this.open_close1){
  if(!this.valid1){
    this.open_date1=this.sel_open_date1;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date1.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
    if( t==null || t1==true ){
      this.open_date1=this.sel_open_date1;
    }
  }
}

if(this.open_close2){
  if(!this.valid2){
    this.open_date2=this.sel_open_date2;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date2.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
    if( t==null || t1==true ){
      this.open_date2=this.sel_open_date2;
    }
  }
}

if(this.open_close3){
  if(!this.valid3){
    this.open_date3=this.sel_open_date3;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date3.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
    if( t==null || t1==true ){
      this.open_date3=this.sel_open_date3;
    }
  }
}

if(this.open_close4){
  if(!this.valid4){
    this.open_date4=this.sel_open_date4;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date4.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
    if( t==null || t1==true ){
      this.open_date4=this.sel_open_date4;
    }
  }
}

if(this.open_close5){
  if(!this.valid5){
    this.open_date5=this.sel_open_date5;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date5.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
    if( t==null || t1==true ){
      this.open_date5=this.sel_open_date5;
    }
  }
}

if(this.open_close6){
  if(!this.valid6){
    this.open_date6=this.sel_open_date6;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date6.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
    if( t==null || t1==true ){
      this.open_date6=this.sel_open_date6;
    }
  }
}

if(this.open_close7){
  if(!this.valid7){
    this.open_date7=this.sel_open_date7;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.open_date7.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
    if( t==null || t1==true ){
      this.open_date7=this.sel_open_date7;
    }
  }
}

if(this.open_close1){
  if(!this.valid11){
    this.close_date1=this.sel_close_date1;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date1.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
    if( t==null || t1==true ){
      this.close_date1=this.sel_close_date1;
    }
  }
}

if(this.open_close2){
  if(!this.valid22){
    this.close_date2=this.sel_close_date2;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date2.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
    if( t==null || t1==true ){
      this.close_date2=this.sel_close_date2;
    }
  }
}

if(this.open_close3){
  if(!this.valid33){
    this.close_date3=this.sel_close_date3;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date3.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
    if( t==null || t1==true ){
      this.close_date3=this.sel_close_date3;
    }
  }
}

if(this.open_close4){
  if(!this.valid44){
    this.close_date4=this.sel_close_date4;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date4.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
    if( t==null || t1==true ){
      this.close_date4=this.sel_close_date4;
    }
  }
}

if(this.open_close5){
  if(!this.valid55){
    this.close_date5=this.sel_close_date5;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date5.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
    if( t==null || t1==true ){
      this.close_date5=this.sel_close_date5;
    }
  }
}

if(this.open_close6){
  if(!this.valid66){
    this.close_date6=this.sel_close_date6;
  }else{
    let reWhiteSpace = /\s/g;
    let t=this.close_date6.match(reWhiteSpace);
   let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
    if( t==null || t1==true ){
      this.close_date6=this.sel_close_date6;
    }
  }
}

  }

  date_change1(){
    
    this.process=false;
    this.complete=false;
    if(/(([01]?[0-9]|2[0-3]):[0-5][0-9] ?([AaPp][Mm]))/.test(this.open_date1)){
      this.valid1=true;
      let t=this.open_date1.split(':');
      if(t[0] > 12){
        this.valid1=false;
      }else{
        this.valid1=true;
      }
     }else{
      this.valid1=false;
     }

    
     
    }
 
  date_change2(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date2)){
     this.valid2=true;
     let t=this.open_date2.split(':');
     if(t[0] > 12){
       this.valid2=false;
     }else{
       this.valid2=true;
     }
     }else{
     this.valid2=false;
     }

     
  }
  date_change3(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date3)){
      this.valid3=true;
     }else{
      this.valid3=false;
     }
     let t=this.open_date3.split(':');
     if(t[0] > 12){
       this.valid3=false;
     }else{
       this.valid3=true;
     }
  }

  date_change4(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date4)){
      this.valid4=true;
      let t=this.open_date4.split(':');
      if(t[0] > 12){
        this.valid4=false;
      }else{
        this.valid4=true;
      }
     }else{
      this.valid4=false;
     }
    
  }

  date_change5(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date5)){
      this.valid5=true;
      let t=this.open_date5.split(':');
      if(t[0] > 12){
        this.valid5=false;
      }else{
        this.valid5=true;
      }
     }else{
      this.valid5=false;
     }

    
  }
  date_change6(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date6)){
      this.valid6=true;
      let t=this.open_date6.split(':');
      if(t[0] > 12){
        this.valid6=false;
      }else{
        this.valid6=true;
      }
     }else{
      this.valid6=false;
     }

    
  }
  date_change7(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.open_date7)){
     this.valid7=true;
     let t=this.open_date7.split(':');
     if(t[0] > 12){
       this.valid7=false;
     }else{
       this.valid7=true;
     }
     }else{
     this.valid7=false;
     }
    
  }

  date_change11(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date1)){
     this.valid11=true;
     let t=this.close_date1.split(':');
     if(t[0] > 12){
       this.valid11=false;
     }else{
       this.valid11=true;
     }
     }else{
     this.valid11=false;
     }
    
  }

  date_change22(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date2)){
     this.valid22=true;
     let t=this.close_date2.split(':');
     if(t[0] > 12){
       this.valid22=false;
     }else{
       this.valid22=true;
     }
     }else{
      this.valid22=false;
     }

    
  }
  date_change33(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date3)){
     this.valid33=true;
     let t=this.close_date3.split(':');
     if(t[0] > 12){
       this.valid33=false;
     }else{
       this.valid33=true;
     }
     }else{
     this.valid33=false;
     }
    
  }
  date_change44(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date4)){
      this.valid44=true;
      let t=this.close_date4.split(':');
      if(t[0] > 12){
        this.valid44=false;
      }else{
        this.valid44=true;
      }
     }else{
     this.valid44=false;
     }
    
  }
  date_change55(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date5)){
      this.valid55=true;
      let t=this.close_date5.split(':');
      if(t[0] > 12){
        this.valid55=false;
      }else{
        this.valid55=true;
      }
     }else{
     this.valid55=false;
     }
    
  }
  date_change66(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date6)){
     this.valid66=true;
     let t=this.close_date6.split(':');
     if(t[0] > 12){
       this.valid66=false;
     }else{
       this.valid66=true;
     }
     }else{
     this.valid66=false;
     }
    
  }
  date_change77(){
    this.process=false;
    this.complete=false;
    if(/((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/.test(this.close_date7)){
     
      this.valid77=true;
      let t=this.close_date7.split(':');
      if(t[0] > 12){
        
        this.valid77=false;
      }else{
        this.valid77=true;
      }
     }else{
     this.valid77=false;
     }
     
    
  }

  get_storeinfo(): void {
    // let elemt = document.getElementById('showloader_store');
    // elemt.style.display = 'block';
    let req='stores/store-hours?store_id='+this.store_id;
    //(req);
     this.apiservice.apicall(req)
        .subscribe(
          resultArray => {
            
              let res:any=[];
              res=resultArray;
              //(res)
             this.store_hour_data= JSON.parse(res.body);
             if(this.store_hour_data!= '[]' && this.store_hour_data.length!=0){
               
               this.country='';
               this.country=this.store_hour_data[0].timezone;
               this.sel_country=this.store_hour_data[0].timezone;
             this.open_close1=(this.store_hour_data[0].open_or_closed)=='1'?true:false;
             this.sel_open_close1=(this.store_hour_data[0].open_or_closed)=='1'?true:false;
             this.open_close2=(this.store_hour_data[1].open_or_closed)=='1'?true:false;
             this.sel_open_close2=(this.store_hour_data[1].open_or_closed)=='1'?true:false;
             this.open_close3=(this.store_hour_data[2].open_or_closed)=='1'?true:false;
             this.sel_open_close3=(this.store_hour_data[2].open_or_closed)=='1'?true:false;
             this.open_close4=(this.store_hour_data[3].open_or_closed)=='1'?true:false;
             this.sel_open_close4=(this.store_hour_data[3].open_or_closed)=='1'?true:false;
             this.open_close5=(this.store_hour_data[4].open_or_closed)=='1'?true:false;
             this.sel_open_close5=(this.store_hour_data[4].open_or_closed)=='1'?true:false;
             this.open_close6=(this.store_hour_data[5].open_or_closed)=='1'?true:false;
             this.sel_open_close6=(this.store_hour_data[5].open_or_closed)=='1'?true:false;
             this.open_close7=(this.store_hour_data[6].open_or_closed)=='1'?true:false;
             this.sel_open_close7=(this.store_hour_data[6].open_or_closed)=='1'?true:false;
              if( this.open_close1==true){
                this.open_date1=this.store_hour_data[0].open_time;
                this.close_date1=this.store_hour_data[0].close_time;
                this.sel_open_date1=this.store_hour_data[0].open_time;
                this.sel_close_date1=this.store_hour_data[0].close_time;
                this.valid1=true;
                this.valid11=true;
              }else{
                this.open_date1='';
                this.close_date1='';
                this.sel_open_date1='';
                this.sel_close_date1='';
                this.valid1=false;
                this.valid11=false;
              }
              if( this.open_close2==true){
                this.open_date2=this.store_hour_data[1].open_time;
                this.close_date2=this.store_hour_data[1].close_time;
                this.sel_open_date2=this.store_hour_data[1].open_time;
                this.sel_close_date2=this.store_hour_data[1].close_time;
                this.valid2=true;
                this.valid22=true;
              }else{
                this.open_date2='';
                this.close_date2='';
                 this.sel_open_date2='';
                 this.sel_close_date2='';
                 this.valid2=false;
                 this.valid22=false;
              }
              if( this.open_close3==true){
                this.open_date3=this.store_hour_data[2].open_time;
                this.close_date3=this.store_hour_data[2].close_time;
                this.sel_open_date3=this.store_hour_data[2].open_time;
                this.sel_close_date3=this.store_hour_data[2].close_time;
                this.valid3=true;
                this.valid33=true;
              }else{
                this.open_date3='';
                this.close_date3='';
                this.sel_open_date3='';
                this.sel_close_date3='';
                this.valid3=false;
                this.valid33=false;
              }
              if( this.open_close4==true){
                this.open_date4=this.store_hour_data[3].open_time;
                this.close_date4=this.store_hour_data[3].close_time;
                this.sel_open_date4=this.store_hour_data[3].open_time;
                this.sel_close_date4=this.store_hour_data[3].close_time;
                this.valid4=true;
                this.valid44=true;
              }else{
                this.open_date4='';
                this.close_date4='';
                this.sel_open_date4='';
                this.sel_close_date4='';
                this.valid4=false;
                this.valid44=false;
              }
              if( this.open_close5==true){
                this.open_date5=this.store_hour_data[4].open_time;
                this.close_date5=this.store_hour_data[4].close_time;
                this.sel_open_date5=this.store_hour_data[4].open_time;
                this.sel_close_date5=this.store_hour_data[4].close_time;
                this.valid5=true;
                this.valid55=true;
              }else{
                this.open_date5='';
                this.close_date5='';
                 this.sel_open_date5='';
                 this.sel_close_date5='';
                 this.valid5=false;
                 this.valid55=false;
              }
              if( this.open_close6==true){
                this.open_date6=this.store_hour_data[5].open_time;
                this.close_date6=this.store_hour_data[5].close_time;
                this.sel_open_date6=this.store_hour_data[5].open_time;
                this.sel_close_date6=this.store_hour_data[5].close_time;
                this.valid6=true;
                this.valid66=true;
              }else{
                this.open_date6='';
                this.close_date6='';
                this.sel_open_date6='';
                this.sel_close_date6='';
                this.valid5=false;
                this.valid55=false;
              }
              if( this.open_close7==true){
                this.open_date7=this.store_hour_data[6].open_time;
                this.close_date7=this.store_hour_data[6].close_time;
                this.sel_open_date7=this.store_hour_data[6].open_time;
                this.sel_close_date7=this.store_hour_data[6].close_time;
                this.valid7=true;
                this.valid77=true;
              }else{
                this.open_date7='';
                this.close_date7='';
                this.sel_open_date7='';
                this.sel_close_date7='';
                this.valid7=false;
                this.valid77=false;
              }
             }

             let elemt = document.getElementById('showloader_store');
             elemt.style.display = 'none';
            
          },
          error => {
            
            //(error);
            swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
                this.modalCtrl.dismiss(false);
              })
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'none';
          }
        )
    }

    open_close1_valid:any=true;
    open_close2_valid:any=true;
    open_close3_valid:any=true;
    open_close4_valid:any=true;
    open_close5_valid:any=true;
    open_close6_valid:any=true;
    open_close7_valid:any=true;

    tConvert(str) {
      var dt = moment(str, ["h:mm  A"]).format("HH:mm");
      let newss = moment(new Date(), 'MM-DD-YYYY HH:mm:ss', true).format("YYYY-MM-DD");
      return  newss + " " + dt + ":00";
    }

    update_hours(){
      
      this.timer_div1=false;
      this.timer_div11=false;
      this.timer_div2=false;
      this.timer_div22=false;
      this.timer_div3=false;
      this.timer_div33=false;
      this.timer_div4=false;
      this.timer_div44=false;
      this.timer_div5=false;
      this.timer_div55=false;
      this.timer_div6=false;
      this.timer_div66=false;
      this.timer_div7=false;
      this.timer_div77=false;

      this.submit_hours=true;
      
    
if(this.open_close1){

var st:any;
var et:any;
//date comparision starts
if(this.valid1){


let reWhiteSpace = /\s/g;
let t=this.open_date1.match(reWhiteSpace);
let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
if( t==null || t1==true ){
  this.open_date1=this.sel_open_date1;
  st = this.tConvert(this.open_date1);
}else{
  st = this.tConvert(this.open_date1);
}
 
}else{
  
  this.open_date1=this.sel_open_date1;
  this.valid1=true;
   st = this.tConvert(this.sel_open_date1);
}

if(this.valid11){
  let reWhiteSpace = /\s/g;
let t=this.close_date1.match(reWhiteSpace);
let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
if( t==null || t1==true ){
  this.close_date1=this.sel_close_date1;
  et = this.tConvert(this.close_date1);
}else{
  et = this.tConvert(this.close_date1);
}
 
}else{
  this.close_date1=this.sel_close_date1;
  this.valid11=true;
  et = this.tConvert(this.sel_close_date1);
}
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.monday_validtime=true;
      }else{
        this.monday_validtime=false;
      }

      //date comparision ends
        if(this.open_date1!=''&&this.close_date1!=''&&this.valid1&&this.valid11&&!this.monday_validtime){
          this.open_close1_valid=true;
        }else{
          this.open_close1_valid=false;
        }
     
    }else{
      this.open_close1_valid=true;
      this.open_date1='';
      this.sel_open_date1='';
      this.close_date1='';
      this.sel_close_date1='';
    }

      if(this.open_close2){
        var st:any;
        var et:any;
      if(this.valid2) {
        
         let reWhiteSpace = /\s/g;
         let t=this.open_date2.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
         if( t==null || t1==true ){
           this.open_date2=this.sel_open_date2;
           st = this.tConvert(this.open_date2);
         }else{
          st = this.tConvert(this.open_date2);
         }
        }else{
          this.open_date2=this.sel_open_date2;
          this.valid2=true;
         st = this.tConvert(this.sel_open_date1);
      }

      if(this.valid22){
       
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
        let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
          et = this.tConvert(this.close_date2);
        }else{
          et = this.tConvert(this.close_date2);
        }
      }else{
        this.close_date2=this.sel_close_date2;
        this.valid22=true;
        et = this.tConvert(this.sel_close_date1);
      }
        //date comparision starts
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.tuesday_validtime=true;
      }else{
        this.tuesday_validtime=false;
      }
      
      //date comparision ends
   
        if(this.open_date2!=''&&this.close_date2!=''&&this.valid2&&this.valid22&&!this.tuesday_validtime){
          this.open_close2_valid=true;
        }else{
          this.open_close2_valid=false;
        }
      }else{
        this.open_close2_valid=true;
        this.open_date2='';
        this.sel_open_date2='';
        this.close_date2='';
        this.sel_close_date2='';
      }

      if(this.open_close3){
        //date comparision starts
        var st:any;
        var et:any;
        if(this.valid3){
          
           let reWhiteSpace = /\s/g;
           let t=this.open_date3.match(reWhiteSpace);
           let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
           if( t==null || t1==true ){
             this.open_date3=this.sel_open_date2;
             st = this.tConvert(this.open_date3);
           }else{
            st = this.tConvert(this.open_date3);
           }
        }else{
          this.open_date3=this.sel_open_close3;
          this.valid3=true;
           st = this.tConvert(this.sel_open_date3);
        }

        if(this.valid33){
         
          let reWhiteSpace = /\s/g;
          let t=this.close_date3.match(reWhiteSpace);
          let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
          if( t==null || t1==true ){
            this.close_date3=this.sel_close_date3;
            et = this.tConvert(this.close_date3);
          }else{
            et = this.tConvert(this.close_date3);
          }
        }else{
          this.close_date3=this.sel_close_date3;
          this.valid33=true;
          et = this.tConvert(this.sel_close_date3);
        }
     
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.wednesday_validtime=true;
      }else{
        this.wednesday_validtime=false;
      }
      //date comparision ends
   
        if(this.open_date3!=''&&this.close_date3!=''&&this.valid3&&this.valid33&&!this.wednesday_validtime){
          this.open_close3_valid=true;
        }else{
          this.open_close3_valid=false;
        }
      }else{
        this.open_close3_valid=true;
        this.open_date3='';
        this.sel_open_date3='';
        this.close_date3='';
        this.sel_close_date3='';
      }

     

      if(this.open_close4){
        //date comparision starts
        var st:any;
        var et:any;
        if(this.valid4){
          
           let reWhiteSpace = /\s/g;
           let t=this.open_date4.match(reWhiteSpace);
           let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
           if( t==null || t1==true ){
             this.open_date4=this.sel_open_date4;
             st = this.tConvert(this.open_date4);
           }else{
            st = this.tConvert(this.open_date4);
           }
          
        }else{
          this.open_date4=this.sel_open_date4;
          this.valid4=true;
          st = this.tConvert(this.sel_open_date4);
         
        }

        if(this.valid44){
         
          let reWhiteSpace = /\s/g;
          let t=this.close_date4.match(reWhiteSpace);
          let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
          if( t==null || t1==true ){
            this.close_date4=this.sel_close_date4;
            et = this.tConvert(this.close_date4);
          }else{
            et = this.tConvert(this.close_date4);
          }
        }else{
          this.close_date4=this.sel_close_date4;
          this.valid44=true;
          et = this.tConvert(this.sel_close_date4);
        }
     
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.thursday_validtime=true;
      }else{
        this.thursday_validtime=false;
      }
      //date comparision ends
   
        if(this.open_date4!=''&&this.close_date4!=''&&this.valid4&&this.valid44&&!this.thursday_validtime){
          this.open_close4_valid=true;
        }else{
          this.open_close4_valid=false;
        }
      }else{
        this.open_close4_valid=true;
        this.open_date4='';
        this.sel_open_date4='';
        this.close_date4='';
        this.sel_close_date4='';
      }

      if(this.open_close5){
        //date comparision starts

        var st:any;
        var et:any;
        if(this.valid5){
          
           let reWhiteSpace = /\s/g;
           let t=this.open_date5.match(reWhiteSpace);
           let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
           if( t==null || t1==true ){
             this.open_date5=this.sel_open_date5;
             st = this.tConvert(this.open_date5);
           }else{
            st = this.tConvert(this.open_date5);
           }
          
        }else{
          this.open_date5=this.sel_open_date5;
          this.valid5=true;
           st = this.tConvert(this.sel_open_date5);
          
        }
        if(this.valid55){
          let reWhiteSpace = /\s/g;
           let t=this.close_date5.match(reWhiteSpace);
           let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
           if( t==null || t1==true ){
             this.close_date5=this.sel_close_date5;
             et = this.tConvert(this.close_date5);
           }else{
            et = this.tConvert(this.close_date5);
           }
        }else{
          this.close_date5=this.sel_close_date5;
          this.valid55=true;
          et = this.tConvert(this.sel_close_date5);
        }
     
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.friday_validtime=true;
       
      }else{
        this.friday_validtime=false;
      }
      //date comparision ends
   
        if(this.open_date5!=''&&this.close_date5!=''&&this.valid5&&this.valid55&&!this.friday_validtime){
          this.open_close5_valid=true;
        }else{
          this.open_close5_valid=false;
        }
      }else{
        this.open_close5_valid=true;
        this.open_date5='';
        this.sel_open_date5='';
        this.close_date5='';
        this.sel_close_date5='';
      }

      if(this.open_close6){
        //date comparision starts

        var st:any;
        var et:any;
        if(this.valid6){
          
          let reWhiteSpace = /\s/g;
          let t=this.open_date6.match(reWhiteSpace);
          let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
          if( t==null || t1==true ){
            this.open_date6=this.sel_open_date6;
            st = this.tConvert(this.open_date6);
          }else{
            st = this.tConvert(this.open_date6);
          }
         
       }else{
         this.open_date6=this.sel_open_date6;
         this.valid6=true;
          st = this.tConvert(this.sel_open_date6);
         
       }
       if(this.valid66){
        

         let reWhiteSpace = /\s/g;
         let t=this.close_date6.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
         if( t==null || t1==true ){
           this.close_date6=this.sel_close_date6;
           et = this.tConvert(this.close_date6);
         }else{
          et = this.tConvert(this.close_date6);
         }
       }else{
         this.close_date6=this.sel_close_date6;
         this.valid66=true;
         et = this.tConvert(this.sel_close_date6);
       }
     
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.saturday_validtime=true;
      }else{
        this.saturday_validtime=false;
      }
      //date comparision ends
   
        if(this.open_date6!=''&&this.close_date6!=''&&this.valid6&&this.valid66&&!this.saturday_validtime){
          this.open_close6_valid=true;
        }else{
          this.open_close6_valid=false;
        }
      }else{
        this.open_close6_valid=true;
        this.open_date6='';
        this.sel_open_date6='';
        this.close_date6='';
        this.sel_close_date6='';
      }

      if(this.open_close7){
        //date comparision starts
        var st:any;
        var et:any;
        if(this.valid7){
          
           let reWhiteSpace = /\s/g;
         let t=this.open_date7.match(reWhiteSpace);
         let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
         if( t==null || t1==true ){
           this.open_date7=this.sel_open_date7;
           st = this.tConvert(this.open_date7);
         }else{
          st = this.tConvert(this.open_date7);
         }
        }else{
          this.open_date7=this.sel_open_date7;
          this.valid7=true;
          st = this.tConvert(this.sel_open_date7);
         
        }

        if(this.valid77){
          
          let reWhiteSpace = /\s/g;
          let t=this.close_date7.match(reWhiteSpace);
          let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
          if( t==null || t1==true ){
            this.close_date7=this.sel_close_date7;
            et = this.tConvert(this.close_date7);
          }else{
            et = this.tConvert(this.close_date7);
          }
        }else{
          this.close_date7=this.sel_close_date7;
          this.valid77=true;
          et = this.tConvert(this.sel_close_date7);
        }
     
      let strs = moment(st, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
      let ends = moment(et, 'yyyy-mm-dd HH:mm:ss.SSSSSS');
       
      var isafter = moment(strs).isAfter(moment(ends)); // true
      if (isafter === true) {
       this.sunday_validtime=true;
      }else{
        this.sunday_validtime=false;
      }
      //date comparision ends
   
        if(this.open_date7!=''&&this.close_date7!=''&&this.valid7&&this.valid77&&!this.sunday_validtime){
          this.open_close7_valid=true;
        }else{
          this.open_close7_valid=false;
        }
      }else{
        this.open_close7_valid=true;
        this.open_date7='';
        this.sel_open_date7='';
        this.close_date7='';
        this.sel_close_date7='';
      }
      

      if(this.country!=''){
        
        if(this.open_close1_valid && this.open_close2_valid && this.open_close3_valid && this.open_close4_valid && this.open_close5_valid && this.open_close6_valid && this.open_close7_valid){
        
        setTimeout(()=>{
          this.process=true;
          this.complete=false;
        }, 200)
         
          this.update_store_hours();
        }else{
          
          this.segment_change('1');
        }
    }else{
      
      this.segment_change('1')
    }
  }


    update_store_hours(): void {
      
      let elemt = document.getElementById('showloader_store');
      elemt.style.display = 'block';
     
        this.process=true;
        this.disabled=true;
        this.complete=false;
     
      let toggle1:any;
      let toggle2:any;
      let toggle3:any;
      let toggle4:any;
      let toggle5:any;
      let toggle6:any;
      let toggle7:any;

      if(this.open_close1){
        toggle1=1;
      }else{
        toggle1=0;
        // this.open_date1='';
        // this.close_date1='';
      }
      if(this.open_close2){
        toggle2=1;
      }else{
        toggle2=0;
      }
      if(this.open_close3){
        toggle3=1;
      }else{
        toggle3=0;
      }
      if(this.open_close4){
        toggle4=1;
      }else{
        toggle4=0;
      }
      if(this.open_close5){
        toggle5=1;
      }else{
        toggle5=0;
      }
      if(this.open_close6){
        toggle6=1;
      }else{
        toggle6=0;
      }
      if(this.open_close7){
        toggle7=1;
      }else{
        toggle7=0;
      }
      let sel_timezone=encodeURIComponent(this.country);
      let req="stores/store-hours?store_id="+this.store_id+"&day_of_week=0&day_of_week=1&day_of_week=2&day_of_week=3&day_of_week=4&day_of_week=5&day_of_week=6&open_or_closed="+toggle1+"&open_or_closed="+toggle2+"&open_or_closed="+toggle3+"&open_or_closed="+toggle4+"&open_or_closed="+toggle5+"&open_or_closed="+toggle6+"&open_or_closed="+toggle7+"&open_time="+this.open_date1+"&open_time="+this.open_date2+"&open_time="+this.open_date3+"&open_time="+this.open_date4+"&open_time="+this.open_date5+"&open_time="+this.open_date6+"&open_time="+this.open_date7+"&close_time="+this.close_date1+"&close_time="+this.close_date2+"&close_time="+this.close_date3+"&close_time="+this.close_date4+"&close_time="+this.close_date5+"&close_time="+this.close_date6+"&close_time="+this.close_date7+"&timezone="+sel_timezone;
      //(req);
       this.apiservice.apicallsignup(req)
          .subscribe(
            resultArray => {
              
                let res:any=[];
                res=resultArray;
                //(res);
                
                this.sel_country=this.country;
                this.sel_open_close1=this.open_close1;
                this.sel_open_close2=this.open_close2;
                this.sel_open_close3=this.open_close3;
                this.sel_open_close4=this.open_close4;
                this.sel_open_close5=this.open_close5;
                this.sel_open_close6=this.open_close6;
                this.sel_open_close7=this.open_close7;
                this.sel_open_date1=this.open_date1;
                this.sel_open_date2=this.open_date2;
                this.sel_open_date3=this.open_date3;
                this.sel_open_date4=this.open_date4;
                this.sel_open_date5=this.open_date5;
                this.sel_open_date6=this.open_date6;
                this.sel_open_date7=this.open_date7;
                this.sel_close_date1=this.close_date1;
                this.sel_open_close2=this.close_date2;
                this.sel_open_close3=this.close_date3;
                this.sel_close_date4=this.close_date4;
                this.sel_close_date5=this.close_date5;
                this.sel_close_date6=this.close_date6;
                this.sel_close_date7=this.close_date7;
                this.complete=true;
                this.process=false;
                this.disabled=false; 
               // this.segment_change('2');       
                let elemt = document.getElementById('showloader_store');
                elemt.style.display = 'none';
                this.get_holidays_api();
                this.get_holiday_data();
                this.get_storeinfo();
               
               //Single save option code starts
               if ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date=='') && (!this.edit_data)){
                setTimeout(()=>{
                  this.modalCtrl.dismiss();
                }, 1500)
              }else{
                //  this.complete=false;
                //  this.process=true;
                this.add_holidays();
                if(this.edit_data){
                  this.update_holidays_api();
                }
              }
            //Single save option code ends
              
            },
            error => {
              this.complete=false;
              this.process=false;
              this.disabled=false;
              //(error);
              swal(
                '',
                'Database error. Please try again later.',
                'error',
                {  closeOnClickOutside: false  }
                ).then(() => {
                  this.modalCtrl.dismiss(false);
                })
            
              let elemt = document.getElementById('showloader_store');
              elemt.style.display = 'none';
            }
          )
      }
  

 
  segment_change(value, apicall?){
    
    if(value=='0'){
      this.outside_event();
      this.prev_cliked=this.sel_seg;
      this.sel_seg=value;
      this.dynamic_class=value;
    //  this.get_store_details();
       if(this.prev_cliked=='1'){
         
         $(".dynamic_heght").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght");
       }else if(this.prev_cliked=='2'){
        
         $(".dynamic_heght1").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght");
         $(".addstoremodal").removeClass("dynamic_heght1");
       
       }else{
         $(".addstoremodal").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght1");
         $(".addstoremodal").removeClass("dynamic_heght");
       }
     
     
    }else if(value=='1'){
     // this.get_storeinfo();
     this.prev_cliked=this.sel_seg;
          this.sel_seg=value;
          this.dynamic_class=value;
     
      if(this.prev_cliked=='1'){
         $(".dynamic_heght").addClass("dynamic_heght");
         $(".dynamic_heght").removeClass("addstoremodal");
         $(".dynamic_heght").removeClass("dynamic_heght1");
       }else if(this.prev_cliked=='2'){
        
      $(".dynamic_heght1").addClass("dynamic_heght");
       $(".dynamic_heght").removeClass("dynamic_heght1");
         $(".dynamic_heght1").removeClass("addstoremodal");
      
       }else{
         $(".addstoremodal").addClass("dynamic_heght");
         $(".addstoremodal").removeClass("dynamic_heght1");
       }
     
    }else{
      
      this.outside_event();
     this.prev_cliked=this.sel_seg;
          this.sel_seg=value;
          this.dynamic_class=value;
     // this.get_holidays_api();
      
      if(this.prev_cliked=='1'){
        
         $(".dynamic_heght").addClass("dynamic_heght1");
         $(".dynamic_heght").removeClass("addstoremodal");
       }else if(this.prev_cliked=='2'){
         $(".dynamic_heght1").addClass("dynamic_heght1");
         $(".dynamic_heght1").removeClass("addstoremodal");
         $(".dynamic_heght1").removeClass("dynamic_heght");
       }else{
         $(".addstoremodal").addClass("dynamic_heght1");
         $(".addstoremodal").removeClass("dynamic_heght");
       }
       }
  }


  segment_change_old(value, apicall?){
    if(value=='0'){
      if((this.country==this.sel_country&&this.open_close1==this.sel_open_close1&&this.open_close2==this.sel_open_close2&&this.open_close3==this.sel_open_close3&&this.sel_open_close4==this.sel_open_close4&&this.open_close5==this.sel_open_close5&&this.open_close6==this.sel_open_close6&&this.open_close7==this.sel_open_close7&&this.open_date1==this.sel_open_date1&&this.open_date2==this.sel_open_date2&&this.open_date3==this.sel_open_date3&&this.open_date4==this.sel_open_date4&&this.open_date5==this.sel_open_date5&&this.open_date6==this.sel_open_date6&&this.open_date7==this.sel_open_date7&&this.close_date1==this.sel_close_date1&&this.close_date2==this.sel_close_date2&&this.close_date3==this.sel_close_date3&&this.close_date4==this.sel_close_date4&&this.close_date5==this.sel_close_date5&&this.close_date6==this.sel_close_date7) && ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date=='')) && (!this.edit_data)){
      this.prev_cliked=this.sel_seg;
      this.sel_seg=value;
      this.dynamic_class=value;
       if(this.prev_cliked=='1'){
         $(".dynamic_heght").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght");
       }else if(this.prev_cliked=='2'){
        
         $(".dynamic_heght1").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght");
         $(".addstoremodal").removeClass("dynamic_heght1");
       
       }else{
         $(".addstoremodal").addClass("addstoremodal");
         $(".addstoremodal").removeClass("dynamic_heght1");
         $(".addstoremodal").removeClass("dynamic_heght");
       }
      }else{
        swal(
          'Please save the changes',
          '',
          'info',
          {  closeOnClickOutside: false  }
          ).then(() => {
           
          })
      }
     
    }else if(value=='1'){
      let name=this.name.trim();
      if((name==this.storedata.store_name&& 
        this.address==this.storedata.store_address&&
        this.phone==this.storedata.phone_number) && ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date=='')) && (!this.edit_data)){
          this.prev_cliked=this.sel_seg;
          this.sel_seg=value;
          this.dynamic_class=value;
      this.get_storeinfo();
      if(this.prev_cliked=='1'){
         $(".dynamic_heght").addClass("dynamic_heght");
         $(".dynamic_heght").removeClass("addstoremodal");
         $(".dynamic_heght").removeClass("dynamic_heght1");
       }else if(this.prev_cliked=='2'){
        
      $(".dynamic_heght1").addClass("dynamic_heght");
       $(".dynamic_heght").removeClass("dynamic_heght1");
         $(".dynamic_heght1").removeClass("addstoremodal");
      
       }else{
         $(".addstoremodal").addClass("dynamic_heght");
         $(".addstoremodal").removeClass("dynamic_heght1");
       }
      }else{
        swal(
          'Please save the changes',
          '',
          'info',
          {  closeOnClickOutside: false  }
          ).then(() => {
           
          })
      }
    }else{
      let name=this.name.trim();
      if((name==this.storedata.store_name&& 
        this.address==this.storedata.store_address&&
        this.phone==this.storedata.phone_number) && (this.country==this.sel_country&&this.open_close1==this.sel_open_close1&&this.open_close2==this.sel_open_close2&&this.open_close3==this.sel_open_close3&&this.sel_open_close4==this.sel_open_close4&&this.open_close5==this.sel_open_close5&&this.open_close6==this.sel_open_close6&&this.open_close7==this.sel_open_close7&&this.open_date1==this.sel_open_date1&&this.open_date2==this.sel_open_date2&&this.open_date3==this.sel_open_date3&&this.open_date4==this.sel_open_date4&&this.open_date5==this.sel_open_date5&&this.open_date6==this.sel_open_date6&&this.open_date7==this.sel_open_date7&&this.close_date1==this.sel_close_date1&&this.close_date2==this.sel_close_date2&&this.close_date3==this.sel_close_date3&&this.close_date4==this.sel_close_date4&&this.close_date5==this.sel_close_date5&&this.close_date6==this.sel_close_date7)){
          this.prev_cliked=this.sel_seg;
          this.sel_seg=value;
          this.dynamic_class=value;
    //  this.get_holidays_api();
      
      if(this.prev_cliked=='1'){
        
         $(".dynamic_heght").addClass("dynamic_heght1");
         $(".dynamic_heght").removeClass("addstoremodal");
       }else if(this.prev_cliked=='2'){
         $(".dynamic_heght1").addClass("dynamic_heght1");
         $(".dynamic_heght1").removeClass("addstoremodal");
         $(".dynamic_heght1").removeClass("dynamic_heght");
       }else{
         $(".addstoremodal").addClass("dynamic_heght1");
         $(".addstoremodal").removeClass("dynamic_heght");
       }
     
    
  }else{
    swal(
      'Please save the changes',
      '',
      'info',
      {  closeOnClickOutside: false  }
      ).then(() => {
       
      })
  }
  }
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  addstore_close(){
    
    if(this.name==''&& this.address=='' && this.phone==''){
      this.closeModal();
    }else{
      this.close_addstore_modal();
    }
  }

  async close_addstore_modal(){
    await this.alertCtrl.create({
      subHeader: 'Discard unsaved changes?',
      cssClass:'alertlog',
      
      buttons: [
      {
      text: "Cancel",
      },
      {
      text: "Discard", handler: (res) => {
        this.modalCtrl.dismiss();
        this.closeModal();
       
      }
      }
      ]
      }).then(res => res.present());
    }


  close(){
    
    let name=this.name.trim();
    if(
      (this.country==this.sel_country&&this.open_close1==this.sel_open_close1&&this.open_close2==this.sel_open_close2&&this.open_close3==this.sel_open_close3&&this.open_close4==this.sel_open_close4&&this.open_close5==this.sel_open_close5&&this.open_close6==this.sel_open_close6&&this.open_close7==this.sel_open_close7&&this.open_date1==this.sel_open_date1&&this.open_date2==this.sel_open_date2&&this.open_date3==this.sel_open_date3&&this.open_date4==this.sel_open_date4&&this.open_date5==this.sel_open_date5&&this.open_date6==this.sel_open_date6&&this.open_date7==this.sel_open_date7&&this.close_date1==this.sel_close_date1&&this.close_date2==this.sel_close_date2&&this.close_date3==this.sel_close_date3&&this.close_date4==this.sel_close_date4&&this.close_date5==this.sel_close_date5&&this.close_date6==this.sel_close_date6&&this.close_date7==this.sel_close_date7) && (name==this.storedata.store_name && this.address==this.storedata.store_address && this.phone==this.storedata.phone_number) 
      && 
      ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date==''))

    && (!this.edit_data)
      ){
        
          this.modalCtrl.dismiss();
        }else{
          
          this.close_modal();
        }
  }


  date_change_method(data, i){
    this.process=false;
    this.complete=false;
    if(this.temp_values.length!=0){
    if(this.temp_values[i].holiday_date == this.store_holiday_data[i].holiday_date){
      this.edit_data=true;
    }else{
      this.edit_data=false;
    }
  }
  }

  async close_modal(){
  await this.alertCtrl.create({
    subHeader: 'Discard unsaved changes?',
    cssClass:'alertlog',
    
    buttons: [
    {
    text: "Cancel",
    },
    {
    text: "Discard", handler: (res) => {
      this.modalCtrl.dismiss();
      this.closeModal()
      //store update changes
      this.name=this.storedata.store_name.trim(); 
      this.address=this.storedata.store_address;
      this.phone=this.storedata.phone_number;

      //store holiday changes
      this.holiday_name='';
      this.holiday_date='';
      this.date='';
      this.store_holiday_data=this.temp_values;

      //Store hours changes
      this.country=this.sel_country;
      this.open_close1=this.sel_open_close1;
      this.open_close2=this.sel_open_close2;
      this.open_close3=this.sel_open_close3;
      this.open_close4=this.sel_open_close4;
      this.open_close5==this.sel_open_close5;
      this.open_close6==this.sel_open_close6;
      this.open_close7==this.sel_open_close7;
      this.open_date1==this.sel_open_date1;
      this.open_date2==this.sel_open_date2;
      this.open_date3==this.sel_open_date3;
      this.open_date4==this.sel_open_date4;
      this.open_date5==this.sel_open_date5;
      this.open_date6==this.sel_open_date6;
      this.open_date7==this.sel_open_date7;
      this.close_date1==this.sel_close_date1;
      this.close_date2==this.sel_close_date2;
      this.close_date3==this.sel_close_date3;
      this.close_date4==this.sel_close_date4;
      this.close_date5==this.sel_close_date5;
      this.close_date6==this.sel_close_date6;
      this.close_date7==this.sel_close_date7;
      this.modalCtrl.dismiss();

    }
    }
    ]
    }).then(res => res.present());
    
  }
 
  ngOnInit() {
    let elemt = document.getElementById('showloader_store');
    elemt.style.display = 'none';
    let usercredentials=JSON.parse(localStorage.getItem("userData"));
    this.userid=usercredentials[0].ID;
    this.userrole='Admin';
   
  }

  add_store(){
    
    this.submit=true;
    
    if(this.name!='' ){
      if(this.phone!=''){
        if(this.invalidphone){
      if(this.storedata==undefined){

      this.add_store_api();
      }else{
       this.update_store_api();
     //  Swal.fire('Oops...', 'Something went wrong!', 'error')
      }
    }
  }else{
      if(this.storedata==undefined){

        this.add_store_api();
        }else{
         this.update_store_api();
       //  Swal.fire('Oops...', 'Something went wrong!', 'error')
        }
    }
    }
    }
  

  add_store_new(){
    
    // this.complete=false;
    // this.process=false;
     this.submit=true;
    //first tab
    let name=this.name.trim()
    if(name==this.storedata.store_name&&
    this.address==this.storedata.store_address&&
    this.phone==this.storedata.phone_number){
    
    //second tab
    if(this.country==this.sel_country&&this.open_close1==this.sel_open_close1&&this.open_close2==this.sel_open_close2&&this.open_close3==this.sel_open_close3&&this.sel_open_close4==this.sel_open_close4&&this.open_close5==this.sel_open_close5&&this.open_close6==this.sel_open_close6&&this.open_close7==this.sel_open_close7&&this.open_date1==this.sel_open_date1&&this.open_date2==this.sel_open_date2&&this.open_date3==this.sel_open_date3&&this.open_date4==this.sel_open_date4&&this.open_date5==this.sel_open_date5&&this.open_date6==this.sel_open_date6&&this.open_date7==this.sel_open_date7&&this.close_date1==this.sel_close_date1&&this.close_date2==this.sel_close_date2&&this.close_date3==this.sel_close_date3&&this.close_date4==this.sel_close_date4&&this.close_date5==this.sel_close_date5&&this.close_date6==this.sel_close_date6&&this.close_date7==this.sel_close_date7){
    
    //third tab
    if ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date=='') && (!this.edit_data)){
      
    this.add_holidays();//commented this for avaoid calling the api when there is no data changes
    }else{
     
    // this.add_holidays_api();
    this.add_holidays();
    if(this.edit_data){
     
    this.update_holidays_api();
    }
    }
    }else{
    
    this.update_hours();
    }
    }else{
    
    if(this.name!='' ){
      if( this.phone!=''){
     if(this.invalidphone){
    this.update_store_api();
     }
    }else{
      this.update_store_api();
    }
    }else{
      this.segment_change('0');
    }
    }
    }
    
    
  name_validate(){
    if(this.name!==''){
      if(/[!@#$%^&*(),.?":{}|<>]/.test(this.name)){
       this.validatename=false;
      }else{
        this.validatename=true;
      }
    }
  }

  add_store_api(){
    this.process=true;
    this.disabled=true;
    this.complete=false;
    //(this.address);
    let addrs=encodeURIComponent(this.address);
    let name=this.name.trim();
    let s_name=encodeURIComponent(name);
    let req='stores?name='+s_name+'&address1='+addrs+'&phone_number='+this.phone+'&user_id='+this.userid+'&role='+this.userrole;
     //(req);
    this.apiservice.apicallsignup(req)
         .subscribe(
           resultArray => {
             
             let res:any=[];
             res=resultArray;
             this.process=false;
             this.disabled=false;
            
             if(res.errorMessage=='duplicate store name!'){
              // swal(
              //   'Store already exists.',
              //   'Please enter a different Store Name',
              //   'warning',
              // { closeOnClickOutside: false }
              
              // ).then(() => {
              
              // })
              this.duplicate_store=true;
              }else{
              this.duplicate_store=false;
            this.complete_status=true;
            setTimeout(()=>{
            this.modalCtrl.dismiss(true);
            this.complete_status=false;
            }, 500)
            }
            },
           error => {
            //(error);
             this.process=false;
             this.disabled=false;
             this.complete=false;
             swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
                this.modalCtrl.dismiss(false);
              })
            })
    }
 
    outside_event(){
    this.timer_div1=false;
    this.timer_div11=false;
    this.timer_div2=false;
    this.timer_div22=false;
    this.timer_div3=false;
    this.timer_div33=false;
    this.timer_div4=false;
    this.timer_div44=false;
    this.timer_div5=false;
    this.timer_div55=false;
    this.timer_div6=false;
    this.timer_div66=false;
    this.timer_div7=false;
    this.timer_div77=false;
    
       //updating the dates on closing the timer
      if(!this.valid1){
        this.open_date1=this.sel_open_date1;
      }else{
        
       let reWhiteSpace = /\s/g;
        let t=this.open_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date1);
        if( t==null || t1==true ){
          this.open_date1=this.sel_open_date1;
        }
      }
      if(!this.valid2){
        this.open_date2=this.sel_open_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date2);
        if( t==null || t1==true ){
          this.open_date2=this.sel_open_date2;
        }
      }
      if(!this.valid3){
        this.open_date3=this.sel_open_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date3);
        if( t==null || t1==true ){
          this.open_date3=this.sel_open_date3;
        }
      }

      if(!this.valid4){
        this.open_date4=this.sel_open_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date4);
        if( t==null || t1==true ){
          this.open_date4=this.sel_open_date4;
        }
      }

      if(!this.valid5){
        this.open_date5=this.sel_open_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date5);
        if( t==null || t1==true ){
          this.open_date5=this.sel_open_date5;
        }
      }

      if(!this.valid6){
        this.open_date6=this.sel_open_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date6);
        if( t==null || t1==true ){
          this.open_date6=this.sel_open_date6;
        }
      }

      if(!this.valid7){
        this.open_date7=this.sel_open_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.open_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.open_date7);
        if( t==null || t1==true ){
          this.open_date7=this.sel_open_date7;
        }
      }

      if(!this.valid11){
        this.close_date1=this.sel_close_date1;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date1.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date1);
        if( t==null || t1==true ){
          this.close_date1=this.sel_close_date1;
        }
      }

      if(!this.valid22){
        this.close_date2=this.sel_close_date2;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date2.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date2);
        if( t==null || t1==true ){
          this.close_date2=this.sel_close_date2;
        }
      }

      if(!this.valid33){
        this.close_date3=this.sel_close_date3;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date3.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date3);
        if( t==null || t1==true ){
          this.close_date3=this.sel_close_date3;
        }
      }

      if(!this.valid44){
        this.close_date4=this.sel_close_date4;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date4.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date4);
        if( t==null || t1==true ){
          this.close_date4=this.sel_close_date4;
        }
      }

      if(!this.valid55){
        this.close_date5=this.sel_close_date5;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date5.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date5);
        if( t==null || t1==true ){
          this.close_date5=this.sel_close_date5;
        }
      }

      if(!this.valid66){
        this.close_date6=this.sel_close_date6;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date6.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date6);
        if( t==null || t1==true ){
          this.close_date6=this.sel_close_date6;
        }
      }

      if(!this.valid77){
        this.close_date7=this.sel_close_date7;
      }else{
        let reWhiteSpace = /\s/g;
        let t=this.close_date7.match(reWhiteSpace);
       let t1=/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\"<>\?]/g.test(this.close_date7);
        if( t==null || t1==true ){
          this.close_date7=this.sel_close_date7;
        }
      }
    }

  //change inputes common method
  change_inputes(){
    this.process=false;
  this.complete=false;
  }
//validation for input fields
phone_validate(){
  this.process=false;
  this.complete=false;
  if(this.phone!=''&&this.phone!=null){
    let phone=this.phone.toString();
    if(/^\d*$/.test(this.phone)&&(phone.length>=10&&phone.length<=16)&&(/^(?=.*\d)(?=.*[1-9]).{1,16}$/.test(this.phone))){
   
  this.invalidphone=true;
    }else{
      this.invalidphone=false;
    }
  }
}

get_store_details(){
  let req='stores/singlestore?store_id='+this.store_id+'&user_id='+this.userid;
  //(req)
  this.apiservice.apicall(req)
  .subscribe(
  resultArray => {
  let instance=this;

  let store_data:any = resultArray;
  let data = JSON.parse(store_data.body);
  //(data[0]);
  this.storedata.phone_number=data[0].phone_number;
  this.storedata.store_address=data[0].store_address;
  this.storedata.store_name=data[0].store_name;
  //(this.storedata);
  
  })
}


update_store_api(){
  
  this.process=true;
  this.disabled=true;
  let addrs=encodeURIComponent(this.address);
  let name=this.name.trim();
  let s_name=encodeURIComponent(name);
   let req='stores?store_name='+s_name+'&store_address='+addrs+'&phone_number='+this.phone+'&store_id='+this.store_id;
  
   //(req);
  this.apiservice.apicallupdate(req)
       .subscribe(
         resultArray => {
           
           let res:any=[];
           res=resultArray;
           this.process=false;
           this.disabled=false;
            //(res);
            this.complete=false;
            if(res.errorMessage=='duplicate store name!'){
              // swal(
              //   'Store already exists.',
              //   'Please enter a different Store Name',
              //   'warning',
              // { closeOnClickOutside: false }
              
              // ).then(() => {
              
              // })
              this.duplicate_store=true;
            }else{
              this.complete=true;
              this.duplicate_store=false;

           // this.segment_change('1');
           this.get_store_details();
              //single save option code starts
              if(this.country==this.sel_country&&this.open_close1==this.sel_open_close1&&this.open_close2==this.sel_open_close2&&this.open_close3==this.sel_open_close3&&this.sel_open_close4==this.sel_open_close4&&this.open_close5==this.sel_open_close5&&this.open_close6==this.sel_open_close6&&this.open_close7==this.sel_open_close7&&this.open_date1==this.sel_open_date1&&this.open_date2==this.sel_open_date2&&this.open_date3==this.sel_open_date3&&this.open_date4==this.sel_open_date4&&this.open_date5==this.sel_open_date5&&this.open_date6==this.sel_open_date6&&this.open_date7==this.sel_open_date7&&this.close_date1==this.sel_close_date1&&this.close_date2==this.sel_close_date2&&this.close_date3==this.sel_close_date3&&this.close_date4==this.sel_close_date4&&this.close_date5==this.sel_close_date5&&this.close_date6==this.sel_close_date6&&this.close_date7==this.sel_close_date7){
                
                              if ((this.show_more&&this.holiday_name==''&&this.holiday_date=='') || (!this.show_more&&this.holiday_name==''&&this.holiday_date=='') && (!this.edit_data)){
                                setTimeout(()=>{
                                  this.modalCtrl.dismiss();
                                }, 1500)
                              }else{
                                this.add_holidays();
                                if(this.edit_data){
                                  this.update_holidays_api();
                                }
                              }
                            }else{
                              
                              this.update_hours();
                            }
                            //single save option code ends
                          }
         },
         error => {
          this.complete=false;
          //(error);
           this.process=false;
           this.disabled=false;
           swal(
            '',
            'Database error. Please try again later.',
            'error',
            {  closeOnClickOutside: false  }
            ).then(() => {
              this.modalCtrl.dismiss(false);
            })
          })
  }

 
  
  //add holidays code
  add_holidays(){
    
    this.submit_holiday=false;
    //To check if user is adding or editing,if user is adding if condition executes else update api is called.
    if(this.holidays_array.length!=0){
      
    this.removeDup();
    }else{
      
    this.update_holidays_api();
    }
    }

    removeDup() {
      var result = [];
      var arr = [];
      var result2 = [];
      var result3 = [];
      arr=this.holidays_array;
      arr.forEach((item) => {
      item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
      });
      //to check duplicates while adding
      arr.forEach((item,index) => {
      var xfind = result.find(function(h){ return h.holiday_date === item.holiday_date }) !== undefined;
      
      if(!xfind){
      result.push(item)
      }
      
      });
      //(result);
      //to check null values while adding
      result.forEach((item2,index) => {
      if(item2.holiday_date != "Invalid date" && item2.holiday_name != ''){
      result2.push(item2);
      }
      else if(item2.holiday_date == "Invalid date"){
      }else if(item2.holiday_name == ''){
      }
      });
      //(result2);
      if(result2.length==0){
      this.holidays_array=[];
      this.update_holidays_api();
      } else{
      this.holidays_array=result2;
      }
      
      
      //to check duplicate dates values already prsent in get api
      //duplicate
      if(this.holidays_array.length==1){
      var searchField = this.details_value;
      let holiday1=moment(this.holidays_array[0].holiday_date).format('MM/DD/YYYY');
      if(searchField.some(searchField => searchField.holiday_date == holiday1)){
      this.holidays_array=[];
      this.get_holidays_api();
      this.get_holiday_data();
      }else{
      this.holidays_array=result2;
      }
      
      }
      this.add_holidays_api();
      }
        
        
        update_val(){
          
        this.temp_values.forEach((item) => {
        item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
        //( item.holiday_date);
        
        });
        this.store_holiday_data.forEach((item) => {
        item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
        //( item.holiday_date);
        
        });
        
        this.store_holiday_data.forEach((item,index) => {
        var xfind1 = this.temp_values.find(function(h){ return h.holiday_date === item.holiday_date }) !== undefined;
        
        if(!xfind1){
        this.diff.push(item)
        }
        //(this.diff);
        });
        if(this.diff.length!=0){
        this.store_holiday_data=this.diff;
        }
        this.removeDuplicate_update();
        }  
        
        
        update_holidays_api(){
          this.removeDuplicate_update();
       }

          
removeDuplicate_update(){
  
  var result = [];
  var arr = [];
  var result3=[];
  // arr=this.store_holiday_data;
  this.store_holiday_data.forEach((item) => {
  item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
  });
  
  arr=[];
  // to check duplicates while editing dates
  arr=this.store_holiday_data;
  
  arr.forEach((item,index) => {
  var xfind = result.find(function(h){ return h.holiday_date === item.holiday_date }) !== undefined;
  
  if(!xfind){
  result.push(item)
  }
  
  });
  //(result);
  this.store_holiday_data=result;
  this.update_holidays_api_cal();
  
  }

          update_holidays_api_cal(){
            
            //(this.temp_values);
            //(this.store_holiday_data);
            if(this.store_holiday_data.length < 1 || this.store_holiday_data == undefined){
            this.get_holidays_api();
            }
            if(this.store_holiday_data.length>0)
            this.process=true;
            this.disabled=true;
            this.complete=false;
            for(let i = 0; i<=this.store_holiday_data.length;i++){
            if ( this.store_holiday_data[i].holiday_name!=''){
            //(this.store_holiday_data[i].holiday_name);
           
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'block';
            let holiday= moment(this.store_holiday_data[i].holiday_date).format('YYYY-MM-DD');
            let holidays_name=encodeURIComponent(this.store_holiday_data[i].holiday_name);
            let req="stores/store-holidays?id="+this.store_holiday_data[i].ID+"&holiday_name="+holidays_name+"&holiday_date="+holiday;
            //(req);
            this.apiservice.apicallupdate(req)
            .subscribe(
            resultArray => {
            
            let res:any=[];
            res=resultArray;
            if(i==this.store_holiday_data.length-1){
            // this.process=false;
            // this.disabled=false;
            // this.show_more=false;
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'none';
          //  this.store_holiday_data=[];
            this.get_holidays_api();
              this.get_holiday_data();
            }
            //(res)
            },
            error => {
              
            //(error);
            this.process=false;
            this.disabled=false;
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'none';
            swal(
            '',
            'Database error. Please try again later.',
            'error',
            { closeOnClickOutside: false }
            ).then(() => {
            this.modalCtrl.dismiss(false);
            })
            })
            }else{
             
            this.get_holidays_api();
            }
            
            }
          }

            update_holidays_api_call_old(){
              
            
              //(this.store_holiday_data);
             if(this.store_holiday_data.length!=0){
              this.process=true;
              this.disabled=true;
              this.complete=false;
              for(let i = 0; i<=this.store_holiday_data.length;i++){
              if ( this.store_holiday_data[i].holiday_name!=''){
             
              let elemt = document.getElementById('showloader_store');
              elemt.style.display = 'block';
              let holiday= moment(this.store_holiday_data[i].holiday_date).format('YYYY-MM-DD');
              let holidays_name=encodeURIComponent(this.store_holiday_data[i].holiday_name);
              let req="stores/store-holidays?id="+this.store_holiday_data[i].ID+"&holiday_name="+holidays_name+"&holiday_date="+holiday;
              //(req);
              this.apiservice.apicallupdate(req)
              .subscribe(
              resultArray => {
              
              let res:any=[];
              res=resultArray;
              if(i==this.store_holiday_data.length-1){
             
              this.show_more=false;
              let elemt = document.getElementById('showloader_store');
              elemt.style.display = 'none';
              this.store_holiday_data=[];
              this.get_holidays_api();
              this.get_holiday_data();
              }
              // this.complete=true;
              // this.process=false;
              // this.disabled=false;
              //(res)
              },
              error => {
              this.complete=false;
              //(error);
              this.process=false;
              this.disabled=false;
              let elemt = document.getElementById('showloader_store');
              elemt.style.display = 'none';
              swal(
              '',
              'Database error. Please try again later.',
              'error',
              { closeOnClickOutside: false }
              ).then(() => {
              this.modalCtrl.dismiss(false);
              })
              })
              }else{
              this.get_holidays_api();
              this.get_holiday_data();
              }
              
              }
            }
              
              }
              
              
      add_holidays_api(){
        
        let req;
          let val1;
            if(this.holidays_array.length!=0){
        //appending params to url when 2 or more holidays are added
        if(this.holidays_array.length>1){
          this.process=true;
          this.disabled=true;
          this.complete=false;
          let elemt = document.getElementById('showloader_store');
          elemt.style.display = 'block';
         
        val1="stores/store-holidays?store_id="+this.store_id;
        for(let i = 0; i<this.holidays_array.length;i++){
        let holiday= moment(this.holidays_array[i].holiday_date).format('YYYY-MM-DD');
        this.holiday_name=encodeURIComponent(this.holidays_array[i].holiday_name);//for special characters
        //checking whether user has added two or more holidays without filling the fields or not
        if(this.holidays_array[i].holiday_name!='' && this.holidays_array[i].holiday_date!=''){
        //duplicate
        var searchField = this.details_value;
        let holiday1=moment(this.holidays_array[i].holiday_date).format('MM/DD/YYYY');
        if(searchField.some(searchField => searchField.holiday_date == holiday1)){
        }else{
        val1 += "&holiday_name="+this.holiday_name+"&holiday_date="+holiday;
        //("the value is ",val1);
        }
        
        }
        }
        req= val1;
        //("the value is ",val1);
        }else{
        
          this.process=true;
          this.disabled=true;
          this.complete=false;
          
        //when user adds only one holiday.
        let holiday= moment(this.holidays_array[0].holiday_date).format('YYYY-MM-DD');
        this.holiday_name=encodeURIComponent(this.holidays_array[0].holiday_name);
        
        req="stores/store-holidays?store_id="+this.store_id+"&holiday_name="+this.holiday_name+"&holiday_date="+holiday;
        }
        //(req);
        this.apiservice.apicallsignup(req)
        .subscribe(
        resultArray => {
          
        let res:any=[];
        res=resultArray;
        // this.complete=true;
        // this.process=false;
        // this.disabled=false;
        this.show_more=false;
        let elemt = document.getElementById('showloader_store');
        elemt.style.display = 'none';
       // this.store_holiday_data=[];
        this.get_holidays_api();
        this.get_holiday_data();
        //(res)
      //  this.holidays_array=[];
       
        },
        error => {
          
        this.complete=false;
        //(error);
        this.process=false;
        this.disabled=false;
        let elemt = document.getElementById('showloader_store');
        elemt.style.display = 'none';
        swal(
        '',
        'Database error. Please try again later.',
        'error',
        { closeOnClickOutside: false }
        ).then(() => {
        this.modalCtrl.dismiss(false);
        })
        })
        }
      }

  get_holiday_data(){
    let req="stores/store-holidays?store_id="+this.store_id;
    //(req);
    this.apiservice.apicall(req)
    .subscribe(
    resultArray => {
    let res:any=[];
    res=resultArray;
    this.temp_values=[];
    let temp_data=JSON.parse(res.body);
    temp_data.forEach((item) => {
    item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
    item.isDisabled = true;
    item.edit = true;
    });
    this.temp_values=temp_data;
    })
}

get_holidays_api(value?){
  
  if(value == undefined){
 setTimeout(()=>{
  this.process=false;
  this.disabled=true;
  this.complete=true;
 }, 500);

 setTimeout(()=>{
   this.modalCtrl.dismiss();
 }, 2000)
   
      }
  this.edit_data=false;
  this.show_more=false;
 
  let req="stores/store-holidays?store_id="+this.store_id;
  //(req);
  this.apiservice.apicall(req)
  .subscribe(
  resultArray => {
  
  let res:any=[];
  res=resultArray;
 
  //(res);
  this.holiday_date='';
  this.holiday_name='';
  this.date='';
  this.submit_holiday=false;
  this.store_holiday_data=[];
  let temp_data=JSON.parse(res.body);
  
  
  this.details_value= temp_data;
  
  
  
  temp_data.forEach((item) => {
  item.holiday_date = moment(item.holiday_date).format('MM/DD/YYYY');
  item.isDisabled = true;
  item.edit = true;
  });
  this.prev_store_data=[];
  temp_data.forEach((item) => {
  this.prev_store_data.push(item);
  });
  
  this.store_holiday_data= temp_data;
  if(this.store_holiday_data.length==0){
    this.holidays_array=[];
  this.holidays_array.push({
  'holiday_name':'',
  'holiday_date':'mm/dd/yyyy'
  })
  }
  //(res)
  let elemt = document.getElementById('showloader_store');
  elemt.style.display = 'none';
  
  },
  error => {
  //(error);
  this.process=false;
  this.disabled=false;
  let elemt = document.getElementById('showloader_store');
  elemt.style.display = 'none';
  swal(
  '',
  'Database error. Please try again later.',
  'error',
  { closeOnClickOutside: false }
  ).then(() => {
  // this.modalCtrl.dismiss(false);
  })
  })
  }
  
  update(data){
   
    this.disabled=true;
    let elemt = document.getElementById('showloader_store');
    elemt.style.display = 'block';
    let holiday= moment(data.holiday_date).format('YYYY-MM-DD');
    let req="stores/store-holidays?id="+data.ID+"&holiday_name="+data.holiday_name+"&holiday_date="+holiday;
   // let req="stores/store-holidays?store_id="+this.store_id+"&holiday_name="+this.holiday_name+"&holiday_date="+holiday;
   //(req);
   this.apiservice.apicallupdate(req)
        .subscribe(
          resultArray => {
            
            let res:any=[];
            data.edit=true;
            data.isDisabled=true;
            res=resultArray;
           this.edit_data=false;
            this.disabled=false;
            this.show_more=false;
            this.get_holidays_api();
            this.get_holiday_data();
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'none';
           
          },
          error => {
           //(error);
           
            this.disabled=false;
            let elemt = document.getElementById('showloader_store');
            elemt.style.display = 'none';
            swal(
             '',
             'Database error. Please try again later.',
             'error',
             {  closeOnClickOutside: false  }
             ).then(() => {
               this.modalCtrl.dismiss(false);
             })
           })
  }

    change_name(data, i){
      this.process=false;
      this.complete=false;
      //(i);
      if(this.temp_values.length!=0){
      if(i<=this.temp_values.length-1){
      if(this.temp_values[i].holiday_name===data.holiday_name){
        this.edit_data=false;
      }else{
        this.edit_data=true;
      }
      }
      }
    }

  input_click(){
  
  }
 
  add_more_holidays(){
    this.show_more=true;
    this.complete=false;
    this.process=false;
    this.holidays_array.push({
    'holiday_name':'',
    'holiday_date':'mm/dd/yyyy'
    })
    }
    
    delete_holiday(index){
      
      if(this.store_holiday_data.length>1){
      this.holidays_array.splice(index, 1)
      }else if(this.holidays_array.length==1){
      this.holidays_array.splice(index, 1)
      }
      else{
      this.holidays_array.splice(index,1)
      if(this.holidays_array.length==0){
        this.holidays_array=[];
      this.holidays_array.push({
      'holiday_name':'',
      'holiday_date':'mm/dd/yyyy'
      })
      }
      }
      }

      
    
    async delete_holidays_api(id){
    
    const modal = await this.modalCtrl.create({
    component: DeleteComponent,
    cssClass:'deletemodal',
    backdropDismiss:false,
    componentProps: {
    store_holiday_id: id
    }
    });
    modal.onDidDismiss()
    .then((data) => {
    if(data.data==true){
    // this.store_holiday_data=[];
    // this.temp_values=[];
    this.get_holidays_api('true');
    this.get_holiday_data();
    }
    });
    return await modal.present();
    }
  }

