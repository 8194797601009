import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP } from '@ionic-native/http/ngx';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { AdminDropdownComponent } from './popover/admin-dropdown/admin-dropdown.component';
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AuthService } from 'angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './popover/profile/profile.component';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AddstoreComponent } from './modals/addstore/addstore.component';
import { AddSensorComponent } from './modals/add-sensor/add-sensor.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { AdduserComponent } from './modals/adduser/adduser.component';
import { ChangePasswordComponent } from './modals/change-password/change-password.component';
import { SensorSettingsComponent } from './modals/sensor-settings/sensor-settings.component';

import { MaterialModule } from './material.module';
import { TooltipDirective } from './tooltip.directive';
import { MyProfileComponent } from './modals/myprofile/myprofile.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AlertPopupComponent } from './modals/alert-popup/alert-popup.component';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { HttpInterceptorProviders} from './interceptors/interceptor-provider';
import { AuthGuard } from './auth.guard';

const googleLoginOptions: LoginOpt = {
  scope: 'profile email'
};
const config = new AuthServiceConfig([
   {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('673949198436-dtbu3pnv99u1618d89mb3qq4otc6gph0.apps.googleusercontent.com', googleLoginOptions)
  },            
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('264037944916796')
  }
]);
export function provideConfig() {
  return config;
}


@NgModule({
  declarations: [AppComponent, AlertPopupComponent, MyProfileComponent, ChangePasswordComponent, SensorSettingsComponent,  AdduserComponent, AdminDropdownComponent, ProfileComponent, AddstoreComponent, AddSensorComponent, DeleteComponent, TooltipDirective],
  entryComponents: [AdminDropdownComponent, AlertPopupComponent, MyProfileComponent, SensorSettingsComponent, ChangePasswordComponent, AdduserComponent, ProfileComponent, AddstoreComponent, AddSensorComponent, DeleteComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    FormsModule,
    MaterialModule,
    UiSwitchModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AmplifyAngularModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    HTTP,
    HttpClientModule,
    HttpClient,
    Network,
    SocialLoginModule,
    AuthService,
    Facebook,
    GooglePlus,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },{  
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    AmplifyService,
    HttpInterceptorProviders,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
