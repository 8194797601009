import { Component, OnInit } from '@angular/core';
import { PopoverController, ModalController,NavParams, AlertController } from '@ionic/angular';
import { Router, Data } from '@angular/router';
const $: any = '';
import { ApiService } from 'src/app/services/api.service';
import swal from '../../../assets/packages/sweetalert';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss'],
})
export class AdduserComponent implements OnInit {
  submit=false;
  user_email:any='';
  role_pic:any=true;
  role_pics:any=false;
  validateemail=true;
  customAlertOptions: any = {
    header: 'Role'
  };
  store_id:any;
  user_id:any; 
  userdata:any;
  role_value:any='Standard User';
  user_id_value:any;
  am_unique_id:any;
   process=false;
  listofusers;
  login_email:any='';
  done_status=false;
  duplicate_email=false;
  constructor(private alertCtrl:AlertController, private navParam:NavParams,private modalCtrl:ModalController, private popoverController:PopoverController,private router:Router,private apiservice:ApiService) {
    this.store_id=localStorage.getItem("store_id");
    let usercredentials=JSON.parse(localStorage.getItem("userData"));
    
    this.user_id=usercredentials[0].ID;
    this.login_email=usercredentials[0].email;
   // this.user_id=localStorage.getItem("user_id");
    this.userdata=this.navParam.get('data');
    
    if(this.userdata!=undefined){
      this.store_id=this.userdata.store_id;
      this.user_id_value=this.userdata.user_id;
      this.user_email=this.userdata.email;
      this.role_value=this.userdata.role;
     this.am_unique_id=this.userdata.am_unique_id;
     this.done_status=false;
    }
   
  }

  ngOnInit() { 
    this.apiservice.userlistdetails().subscribe(data => {
      //("........inside model", data);
      this.listofusers = data;
    })

  }

  //disacard changes code starts
  closeModal(){
    this.modalCtrl.dismiss();
  }

  modal_close(){
    
    if(this.userdata==undefined){
    if(this.user_email==''){
      this.closeModal();
    }else{
      this.close_adduser_modal();
    }
  }else{
    if( this.role_value==this.userdata.role){
      this.closeModal();
    }else{
      this.close_adduser_modal();
    }
  }
 
  }

  async close_adduser_modal(){
    await this.alertCtrl.create({
      subHeader: 'Discard unsaved changes?',
      cssClass:'alertlog',
      
      buttons: [
      {
      text: "Cancel",
      },
      {
      text: "Discard", handler: (res) => {
        this.modalCtrl.dismiss();
        this.closeModal();
      }
      }
      ]
      }).then(res => res.present());
    }
   //disacard changes code ends
  
  email_validate(){
    this.done_status=false;
    if(this.user_email!==''){
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.user_email)){
       this.validateemail=true;
      }else{
        this.validateemail=false;
      }
    }
  }
  

  invite_user(){
    this.submit=true;
    if(this.user_email!='' && this.role_value!='' && this.validateemail ){
     this.invite_user_api();
    }
  }
  async open_modal(type, message){
    this.modalCtrl.dismiss(true);
  const modal = await this.modalCtrl.create({
    component: AlertPopupComponent,
    cssClass:'popupmodal',
    backdropDismiss:false,
    componentProps: { 
      type:type,
      message:message
    }
    });
    modal.onDidDismiss()
    .then((data) => {
     
     });
    return await modal.present();
  
}


invite_user_api(){
  
  //(this.listofusers);
 
     var searchField = this.listofusers;
     var searchvalue = this.user_email;

     if(this.user_email != this.login_email){
     
     if(searchField.some(searchField => searchField.email == searchvalue)){
     
      // swal(
      //   'Email Id already exists.',
      //   'Please enter a different Email Id',
      //   'warning',
      //   {  closeOnClickOutside: false  }
       
      //    )
      this.duplicate_email=true;
    } else {
      this.duplicate_email=false;
      this.process=true;
  //(this.user_id)  
   //  let req='adminmodule/inviteuser?user_email='+this.user_email+'&given_name=Ankitha&family_name=P&role='+this.role_value+'&store_id='+this.store_id+'&owner_id='+this.user_id+'&admin_id='+this.user_id+'&phone_number=%2B14450126305';
 let req='adminmodule/inviteuser?user_email='+this.user_email+'&role='+this.role_value+'&store_id='+this.store_id;
 
   //(req);
  this.apiservice.apicall_inviteuser(req)
           .subscribe(
             resultArray => {
               
               this.process=false;
               let res:any=[];
               res=resultArray;
               this.done_status=true;
               this.open_modal('success', 'User invited successfully');
               this.modalCtrl.dismiss(true);

             },
             error => {
              //(error);
               this.process=false;
               //("Error :: " + error)
               swal(
                '',
                'Database error. Please try again later.',
                'error',
                {  closeOnClickOutside: false  }
                ).then(() => {
                  this.modalCtrl.dismiss(false);
                })
              })
            }
          }else{
            
            this.duplicate_email=true;
          }
    }

  cancel(){
    this.router.navigate(['/managestores']);
  }

  close(){
    if(this.done_status){
      this.modalCtrl.dismiss(true)
      }else{
        this.modalCtrl.dismiss()
      }
  }

update_user(){
  this.submit=true;
  if(this.user_email!='' &&  this.role_value!='' ){
     this.update_user_api();
   }
 } 

update_user_api(){
  this.process=true;
   let req='adminmodule?role='+this.role_value+'&store_id='+this.store_id+'&user_id='+this.user_id_value;
   //(req);
    this.apiservice.apicall_edituser(req)
             .subscribe(
               resultArray => {
                this.process=false;
                this.done_status=true;
                let res:any=[];
                res=resultArray;
                setTimeout(()=>{
                   this.modalCtrl.dismiss(true);
                  },500)
              },
              error => {
                //(error);
                this.process=false;
                //("Error :: " + error)
                swal(
                  '',
                  'Database error. Please try again later.',
                  'error',
                  {  closeOnClickOutside: false  }
                  ).then(() => {
                    this.modalCtrl.dismiss(false);
                  })
               })
  }

}
