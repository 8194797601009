import { Component, OnInit } from '@angular/core';
import { ModalController, MenuController, NavParams, AlertController } from '@ionic/angular';
import { checkAvailability } from '@ionic-native/core';
import * as $ from "jquery";
import swal from '../../../assets/packages/sweetalert';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { disableDebugTools } from '@angular/platform-browser';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-sensor-settings',
  templateUrl: './sensor-settings.component.html',
  styleUrls: ['./sensor-settings.component.scss'],
})
export class SensorSettingsComponent implements OnInit {
  done_status=false;
 // enable_alert: boolean;
  isTermsAccepted = false;
  user_store_role: any='';
  users: any[];
  get_Sensorinfo: any;
  sensors: any[];
  user_id: any='';
  store_id: any='';
  username: any='';
  data:any='';
  Check=false;
  phone: any='';
  email: any='';
  process=false;
  disabled=false;
  message_type: any='';
  send_to: any='';
  sensor_id: any='';
  radio_vlue:any;
  active_flag:any;
  alert_data:any=[];
  type1=false;
  type2=false;
  send_to1:any='';
  send_to2:any='';
  invalidphone=true;
  submit=false;
  country:any='USA';
  country_code:any='+1';
  county_length=2;
  timer_div1=false;
  prev_phone='';
  prev_send_to1='';
  prev_send_to2='';
  prev_country_code='+1';
  countres = [
    { country: "Afghanistan", country_name:'AFG', code: "+93" },
    { country: "Aland Islands", country_name:'ALA', code: "+358" },
    { country: "Albania", country_name:'ALB', code: "+355" },
    { country: "Algeria", country_name:'DZA', code: "+213" },
    { country: "American Samoa", country_name:'ASM', code: "+1684" },
    { country: "Andorra", country_name:'AND', code: "+376" },
    { country: "Angola", country_name:'AGO', code: "+244" },
    { country: "Anguilla", country_name:'AIA', code: "+1264" },
    { country: "Antarctica", country_name:'ATA', code: "+672" },
    { country: "Antigua and Barbuda", country_name:'ATG', code: "+1268" },
    { country: "Argentina", country_name:'ARG', code: "+54" },
    { country: "Armenia", country_name:'ARM', code: "+374" },
    { country: "Aruba", country_name:'ABW', code: "+297" },
    { country: "Australia", country_name:'AUS', code: "+61" },
    { country: "Austria", country_name:'AUT', code: "+43" },
    { country: "Azerbaijan", country_name:'AZE', code: "+994" },
    { country: "Bahamas", country_name:'BHS', code: "+1242" },
    { country: "Bahrain", country_name:'BHR', code: "+973" },
    { country: "Bangladesh", country_name:'BGD', code: "+880" },
    { country: "Barbados", country_name:'BRB', code: "+1246" },
    { country: "Belarus", country_name:'BLR', code: "+375" },
    { country: "Belgium", country_name:'BEL', code: "+32" },
    { country: "Belize", country_name:'BLZ', code: "+501" },
    { country: "Benin", country_name:'BEN', code: "+229" },
    { country: "Bermuda", country_name:'BMU', code: "+1441" },
    { country: "Bhutan", country_name:'BTN', code: "+975" },
    { country: "Bolivia, Plurinational State of", country_name:'BOL', code: "+591" },
    { country: "Bosnia and Herzegovina", country_name:'BIH', code: "+387" },
    { country: "Botswana", country_name:'BWA', code: "+267" },
    { country: "Brazil", country_name:'BRA', code: "+55" },
    { country: "British Indian Ocean Territory",country_name:'IOT',  code: "+246" },
    { country: "Brunei Darussalam", country_name:'BRN', code: "+673" },
    { country: "Bulgaria", country_name:'BGR', code: "+359" },
    { country: "Burkina Faso", country_name:'BFA', code: "+226" },
    { country: "Burundi", country_name:'BDI', code: "+257" },
    { country: "Cambodia", country_name:'KHM', code: "+855" },
    { country: "Cameroon", country_name:'CMR', code: "+237" },
    { country: "Canada", country_name:'CAN', code: "+1" },
    { country: "Cape Verde", country_name:'CPV', code: "+238" },
    { country: "Cayman Islands", country_name:'CYM', code: "+ 345" },
    { country: "Central African Republic", country_name:'CAF', code: "+236" },
    { country: "Chad", country_name:'TCD', code: "+235" },
    { country: "Chile", country_name:'CHL', code: "+56" },
    { country: "China", country_name:'CHN', code: "+86" },
    { country: "Christmas Island", country_name:'CXR', code: "+61" },
    { country: "Cocos (Keeling) Islands", country_name:'CCK', code: "+61" },
    { country: "Colombia", country_name:'COL', code: "+57" },
    { country: "Comoros", country_name:'COM', code: "+269" },
    { country: "Congo", country_name:'COG', code: "+242" },
    { country: "Congo, The Democratic Republic of the Congo", country_name:'COD', code: "+243" },
    { country: "Cook Islands", country_name:'COK', code: "+682" },
    { country: "Costa Rica", country_name:'CRI', code: "+506" },
    { country: "Cote d'Ivoire", country_name:'CIV', code: "+225" },
    { country: "Croatia", country_name:'HRV', code: "+385" },
    { country: "Cuba", country_name:'CUB', code: "+53" },
    { country: "Cyprus", country_name:'CYP', code: "+357" },
    { country: "Czech Republic", country_name:'CZE', code: "+420" },
    { country: "Denmark", country_name:'DNK', code: "+45" },
    { country: "Djibouti", country_name:'DJI', code: "+253" },
    { country: "Dominica", country_name:'DMA', code: "+1767" },
    { country: "Dominican Republic", country_name:'DOM', code: "+1849" },
    { country: "Ecuador", country_name:'ECU', code: "+593" },
    { country: "Egypt", country_name:'EGY', code: "+20" },
    { country: "El Salvador", country_name:'SLV', code: "+503" },
    { country: "Equatorial Guinea", country_name:'GNQ', code: "+240" },
    { country: "Eritrea", country_name:'ERI', code: "+291" },
    { country: "Estonia", country_name:'EST', code: "+372" },
    { country: "Ethiopia", country_name:'ETH', code: "+251" },
    { country: "Falkland Islands (Malvinas)", country_name:'FLK', code: "+500" },
    { country: "Faroe Islands", country_name:'FRO', code: "+298" },
    { country: "Fiji", country_name:'FJI', code: "+679" },
    { country: "Finland", country_name:'FIN', code: "+358" },
    { country: "France", country_name:'FRA', code: "+33" },
    { country: "French Guiana", country_name:'GUF', code: "+594" },
    { country: "French Polynesia", country_name:'PYF', code: "+689" },
    { country: "Gabon", country_name:'GAB', code: "+241" },
    { country: "Gambia", country_name:'GMB', code: "+220" },
    { country: "Georgia", country_name:'GEO', code: "+995" },
    { country: "Germany", country_name:'DEU', code: "+49" },
    { country: "Ghana", country_name:'GHA', code: "+233" },
    { country: "Gibraltar", country_name:'GIB', code: "+350" },
    { country: "Greece", country_name:'GRC', code: "+30" },
    { country: "Greenland", country_name:'GRL', code: "+299" },
    { country: "Grenada", country_name:'GRD', code: "+1473" },
    { country: "Guadeloupe", country_name:'GLP', code: "+590" },
    { country: "Guam", country_name:'GUM', code: "+1671" },
    { country: "Guatemala", country_name:'GTM', code: "+502" },
    { country: "Guernsey", country_name:'GGY', code: "+44" },
    { country: "Guinea", country_name:'GIN', code: "+224" },
    { country: "Guinea-Bissau", country_name:'GNB', code: "+245" },
    { country: "Guyana", country_name:'GUY', code: "+595" },
    { country: "Haiti", country_name:'HTI', code: "+509" },
    { country: "Holy See (Vatican City State)", country_name:'VAT', code: "+379" },
    { country: "Honduras", country_name:'HND', code: "+504" },
    { country: "Hong Kong", country_name:'HKG', code: "+852" },
    { country: "Hungary", country_name:'HUN', code: "+36" },
    { country: "Iceland", country_name:'ISL', code: "+354" },
    { country: "India", country_name:'IND', code: "+91" },
    { country: "Indonesia", country_name:'IDN', code: "+62" },
    { country: "Iran, Islamic Republic of Persian Gulf", country_name:'IRN', code: "+98" },
    { country: "Iraq", country_name:'IRQ', code: "+964" },
    { country: "Ireland", country_name:'IRL', code: "+353" },
    { country: "Isle of Man", country_name:'IMN', code: "+44" },
    { country: "Israel", country_name:'ISR', code: "+972" },
    { country: "Italy", country_name:'ITA', code: "+39" },
    { country: "Jamaica", country_name:'JAM', code: "+1876" },
    { country: "Japan", country_name:'JPN', code: "+81" },
    { country: "Jersey", country_name:'JEY', code: "+44" },
    { country: "Jordan", country_name:'JOR', code: "+962" },
    { country: "Kazakhstan", country_name:'KAZ', code: "+77" },
    { country: "Kenya", country_name:'KEN', code: "+254" },
    { country: "Kiribati", country_name:'KIR', code: "+686" },
    { country: "Korea, Democratic People's Republic of Korea", country_name:'PRK', code: "+850" },
    { country: "Korea, Republic of South Korea", country_name:'KOR', code: "+82" },
    { country: "Kuwait", country_name:'KWT', code: "+965" },
    { country: "Kyrgyzstan", country_name:'KGZ', code: "+996" },
    // { country: "Laos", country_name:'', code: "+856" },
    { country: "Latvia", country_name:'LVA', code: "+371" },
    { country: "Lebanon", country_name:'LBN', code: "+961" },
    { country: "Lesotho", country_name:'LSO', code: "+266" },
    { country: "Liberia", country_name:'LBR', code: "+231" },
    // { country: "Libyan Arab Jamahiriya", country_name:'', code: "+218" },
    { country: "Liechtenstein", country_name:'LIE', code: "+423" },
    { country: "Lithuania", country_name:'LTU', code: "+370" },
    { country: "Luxembourg", country_name:'LUX', code: "+352" },
    { country: "Macao", country_name:'MAC', code: "+853" },
    // { country: "Macedonia", country_name:'', code: "+389" },
    { country: "Madagascar", country_name:'MDG', code: "+261" },
    { country: "Malawi", country_name:'MWI', code: "+265" },
    { country: "Malaysia", country_name:'MYS', code: "+60" },
    { country: "Maldives", country_name:'MDV', code: "+960" },
    { country: "Mali", country_name:'MLI', code: "+223" },
    { country: "Malta", country_name:'MLT', code: "+356" },
    { country: "Marshall Islands", country_name:'MHL', code: "+692" },
    { country: "Martinique", country_name:'MTQ', code: "+596" },
    { country: "Mauritania", country_name:'MRT', code: "+222" },
    { country: "Mauritius", country_name:'MUS', code: "+230" },
    { country: "Mayotte", country_name:'MYT', code: "+262" },
    { country: "Mexico", country_name:'MEX', code: "+52" },
    { country: "Micronesia, Federated States of Micronesia", country_name:'FSM', code: "+691" },
    { country: "Moldova", country_name:'MDA', code: "+373" },
    { country: "Monaco", country_name:'MCO', code: "+377" },
    { country: "Mongolia", country_name:'MNG', code: "+976" },
    { country: "Montenegro", country_name:'MNE', code: "+382" },
    { country: "Montserrat", country_name:'MSR', code: "+1664" },
    { country: "Morocco", country_name:'MAR', code: "+212" },
    { country: "Mozambique", country_name:'MOZ', code: "+258" },
    { country: "Myanmar", country_name:'MMR', code: "+95" },
    { country: "Namibia", country_name:'NAM', code: "+264" },
    { country: "Nauru", country_name:'NRU', code: "+674" },
    { country: "Nepal", country_name:'NPL', code: "+977" },
    { country: "Netherlands", country_name:'NLD', code: "+31" },
    { country: "Netherlands Antilles", country_name:'NLD', code: "+599" },
    { country: "New Caledonia", country_name:'NCL', code: "+687" },
    { country: "New Zealand", country_name:'NZL', code: "+64" },
    { country: "Nicaragua", country_name:'NIC', code: "+505" },
    { country: "Niger", country_name:'NER', code: "+227" },
    { country: "Nigeria", country_name:'NGA', code: "+234" },
    { country: "Niue", country_name:'NIU', code: "+683" },
    { country: "Norfolk Island", country_name:'NFK', code: "+672" },
    { country: "Northern Mariana Islands", country_name:'MNP', code: "+1670" },
    { country: "Norway", country_name:'NOR', code: "+47" },
    { country: "Oman", country_name:'OMN', code: "+968" },
    { country: "Pakistan", country_name:'PAK', code: "+92" },
    { country: "Palau", country_name:'PLW', code: "+680" },
    { country: "Palestinian Territory, Occupied", country_name:'PSE', code: "+970" },
    { country: "Panama", country_name:'PAN', code: "+507" },
    { country: "Papua New Guinea", country_name:'PNG',  code: "+675" },
    { country: "Paraguay", country_name:'PRY', code: "+595" },
    { country: "Peru", country_name:'PER',  code: "+51" },
    { country: "Philippines", country_name:'PHL', code: "+63" },
    { country: "Pitcairn", country_name:'PCN', code: "+872" },
    { country: "Poland", country_name:'POL', code: "+48" },
    { country: "Portugal", country_name:'PRT', code: "+351" },
    { country: "Puerto Rico", country_name:'PRI', code: "+1939" },
    { country: "Qatar", country_name:'QAT', code: "+974" },
    { country: "Romania", country_name:'ROU', code: "+40" },
    { country: "Russia", country_name:'RUS', code: "+7" },
    { country: "Rwanda", country_name:'RWA', code: "+250" },
    { country: "Reunion", country_name:'REU', code: "+262" },
    { country: "Saint Barthelemy", country_name:'BLM', code: "+590" },
    { country: "Saint Helena, Ascension and Tristan Da Cunha", country_name:'SHN', code: "+290" },
    { country: "Saint Kitts and Nevis", country_name:'KNA', code: "+1869" },
    { country: "Saint Lucia", country_name:'LCA', code: "+1758" },
    { country: "Saint Martin", country_name:'MAF', code: "+590" },
    { country: "Saint Pierre and Miquelon", country_name:'SPM', code: "+508" },
    { country: "Saint Vincent and the Grenadines", country_name:'VCT', code: "+1784" },
    { country: "Samoa", country_name:'WSM', code: "+685" },
    { country: "San Marino", country_name:'SMR', code: "+378" },
    { country: "Sao Tome and Principe", country_name:'STP', code: "+239" },
    { country: "Saudi Arabia", country_name:'SAU', code: "+966" },
    { country: "Senegal", country_name:'SEN', code: "+221" },
    { country: "Serbia", country_name:'SRB', code: "+381" },
    { country: "Seychelles", country_name:'SYC', code: "+248" },
    { country: "Sierra Leone", country_name:'SLE', code: "+232" },
    { country: "Singapore", country_name:'SGP', code: "+65" },
    { country: "Slovakia", country_name:'SVK', code: "+421" },
    { country: "Slovenia", country_name:'SVN', code: "+386" },
    { country: "Solomon Islands", country_name:'SLB', code: "+677" },
    { country: "Somalia", country_name:'SOM', code: "+252" },
    { country: "South Africa", country_name:'ZAF', code: "+27" },
    { country: "South Sudan", country_name:'SSD', code: "+211" },
    { country: "South Georgia and the South Sandwich Islands", country_name:'AFG', code: "+500" },
    { country: "Spain", country_name:'ESP', code: "+34" },
    { country: "Sri Lanka", country_name:'LKA', code: "+94" },
    { country: "Sudan", country_name:'SDN', code: "+249" },
    { country: "Suriname", country_name:'SUR', code: "+597" },
    { country: "Svalbard and Jan Mayen", country_name:'SJM', code: "+47" },
    { country: "Swaziland", country_name:'CHE', code: "+268" },
    { country: "Sweden", country_name:'SWE', code: "+46" },
    { country: "Switzerland", country_name:'CHE', code: "+41" },
    { country: "Syrian Arab Republic", country_name:'SYR', code: "+963" },
    { country: "Taiwan", country_name:'TWN', code: "+886" },
    { country: "Tajikistan", country_name:'TJK', code: "+992" },
    { country: "Tanzania, United Republic of Tanzania", country_name:'TZA', code: "+255" },
    { country: "Thailand", country_name:'THA', code: "+66" },
    { country: "Timor-Leste", country_name:'TLS', code: "+670" },
    { country: "Togo", country_name:'TGO', code: "+228" },
    { country: "Tokelau", country_name:'TKL', code: "+690" },
    { country: "Tonga", country_name:'TON', code: "+676" },
    { country: "Trinidad and Tobago",  country_name:'TTO', code: "+1868" },
    { country: "Tunisia", country_name:'TUN', code: "+216" },
    { country: "Turkey", country_name:'TUR', code: "+90" },
    { country: "Turkmenistan", country_name:'TKM', code: "+993" },
    { country: "Turks and Caicos Islands", country_name:'TCA', code: "+1649" },
    { country: "Tuvalu", country_name:'TUV', code: "+688" },
    { country: "Uganda", country_name:'UGA', code: "+256" },
    { country: "Ukraine", country_name:'UKR', code: "+380" },
    { country: "United Arab Emirates", country_name:'ARE', code: "+971" },
    { country: "United Kingdom", country_name:'GBR', code: "+44" },
    { country: "United States", country_name:'USA', code: "+1" },
    { country: "Uruguay", country_name:'URY', code: "+598" },
    { country: "Uzbekistan", country_name:'UZB', code: "+998" },
    { country: "Vanuatu", country_name:'VUT', code: "+678" },
    { country: "Venezuela, Bolivarian Republic of Venezuela", country_name:'VEN', code: "+58" },
    { country: "Vietnam", country_name:'VNM', code: "+84" },
    { country: "Virgin Islands, British", country_name:'VGB', code: "+1284" },
    { country: "Virgin Islands, U.S.", country_name:'VIR', code: "+1340" },
    { country: "Wallis and Futuna", country_name:'WLF', code: "+681" },
    { country: "Yemen", country_name:'YEM', code: "+967" },
    { country: "Zambia", country_name:'ZMB', code: "+260" },
    { country: "Zimbabwe", country_name:'ZWE', code: "+263" }
    ];
    prev_data:any=[];
  constructor(private alertCtrl:AlertController, private modalCtrl: ModalController, public apiservice:ApiService, public router:Router,  public menu:MenuController, private navParam:NavParams) { 
    
    this.user_store_role=this.navParam.get('role_type');
    let data=this.navParam.get('data');
    this.sensor_id=data.ID;
  }

  country_change(){
    this.done_status=false;
    this.countres.forEach((item) => {
     if(item.country_name==this.country){
       this.country_code=item.code;
      // this.prev_country_code=this.country_code;
     }
    });
   this.county_length=this.country_code.length;
  }

  ngOnInit() {
    let usercredentials = JSON.parse(localStorage.getItem("userData"));
    this.email=usercredentials[0].email;
    this.user_id=usercredentials[0].ID;
    this.get_alert_api();
    this.done_status=false;
  }

   //disacard changes code starts
   closeModal(){
    this.modalCtrl.dismiss();
  }

  modal_close(){
    
   if(this.alert_data.length!=0){
    if(this.prev_data.active_flag == this.active_flag && this.prev_data.message_type == this.message_type && this.phone==this.prev_phone && this.send_to1==this.prev_send_to1 && this.send_to2==this.prev_send_to2 && this.country_code==this.prev_country_code){
      this.closeModal();
    }else{
      this.close_adduser_modal();
    }
  }else{
    if( this.Check ){
      this.close_adduser_modal();
    }else{
    this.closeModal();
    }
  }
 
  }
 
  

  async close_adduser_modal(){
    await this.alertCtrl.create({
      subHeader: 'Discard unsaved changes?',
      cssClass:'alertlog',
      
      buttons: [
      {
      text: "Cancel",
      },
      {
      text: "Discard", handler: (res) => {
        this.modalCtrl.dismiss();
        this.closeModal();
      }
      }
      ]
      }).then(res => res.present());
    }
   //disacard changes code ends
  

  phone_validate(){
    this.done_status=false;
    if(this.phone!=''&&this.phone!=null){
      let phone=this.phone.toString();
      if(/^\d*$/.test(this.phone)&&(phone.length>=10&&phone.length<=16)&&(/^(?=.*\d)(?=.*[1-9]).{1,16}$/.test(this.phone))){  
    this.invalidphone=true;
      }else{
  
        this.invalidphone=false;
      }
    }
  }

  timer1(){
    this.timer_div1=!this.timer_div1;
  }
  

  get_alert_api(){
    
    let req='alarm-config?sensor_id='+this.sensor_id+'&user_id='+this.user_id;
    //(req);
    this.apiservice.apicall(req)
    .subscribe(
    resultArray => {
   
    
    
    let res:any=[];
    res=resultArray;
    
    this.alert_data = JSON.parse(res.body);
    //(this.alert_data);
    this.prev_data=this.alert_data[0];
    this.active_flag=this.alert_data[0].active_flag;
    this.message_type=this.alert_data[0].message_type;
   
    if(this.active_flag==1){
      this.Check=true;
      if(this.message_type==0){
        this.type1=true;
        this.type2=false;
        this.send_to1=this.alert_data[0].send_to;
        this.prev_send_to1=this.send_to1;
        this.phone=this.alert_data[0].send_to;
        this.prev_phone=this.phone;
        if(this.alert_data[0].country_code!=null){
        this.country_code=this.alert_data[0].country_code;
        this.county_length=this.country_code.length;
        this.countres.forEach((item) => {
          if(item.code==this.country_code){
            this.country=item.country_name;
            this.done_status=false;
          
          }
         });
        }
      }else if(this.message_type==1){
        this.type2=true;
        this.type1=false;
        this.send_to2=this.alert_data[0].send_to;
        this.prev_send_to2=this.send_to2;
      }else{
        this.type1=true;
        this.type2=true;
        let type=this.alert_data[0].message_type;
        let values=this.alert_data[0].send_to;
        if(this.alert_data[0].country_code!=null){
        this.country_code=this.alert_data[0].country_code;
        this.county_length=this.country_code.length;
        this.done_status=true;
        }
       
        let values1=values.split(' ');
        this.message_type=2;
        this.send_to1=values1[0];
        this.send_to2=values1[1];
        this.phone=values1[0];
        this.prev_phone=this.phone;
        this.prev_send_to1=this.send_to1;
        this.prev_send_to2=this.send_to2;
        this.prev_country_code=this.country_code;
        this.countres.forEach((item) => {
          if(item.code==this.country_code){
            this.country=item.country_name;
          
          }
         });
        
      }
 
      //(this.Check)
      }else{
      this.Check=false;
      this.type1=false;
      this.type2=false;
      }
    
    //this.showerr=false;
    },
    error => {
    
    //(this.Check)
    //(error);
    
    }
    )
    }
   
  add_alert(){
    this.submit=true;
    if(this.Check){

    if(( this.type1 || this.type2) )
    {
      if(this.type1){
        if(this.phone!='' && this.invalidphone){
          this.add_alert_api();
        }

      }
      else{
        this.add_alert_api();
      }
      //('message');
    
    }
  }else if(!this.Check){
    this.add_alert_api();
  }
  }

  radio_change(value){
    
   if(value=='sms'){
     this.message_type=0;
     this.send_to=this.phone;
   }else{
    this.message_type=1;
    this.send_to=this.email;
    
   }
  }

  select_type1(value){
    
    if(!this.type1==true && this.type2==true){
      this.message_type=2;
    }else if(this.type1==false && this.type2==false){
      this.message_type=0;
    }else if(this.type1==true && this.type2==true){
      this.message_type=1;
    }else{
      this.message_type='';
    }
    if(value){
      this.send_to1='';
      this.phone='';
      this.country_code='+1';
      this.country='USA';
      this.done_status=false;
      this.prev_country_code=this.country_code;
      this.prev_send_to1=this.send_to1;
      this.prev_send_to2=this.send_to2;
      this.prev_phone=this.phone;
    }else{
      this.send_to1=this.phone;
      this.country_code='+1';
      this.country='USA';
      this.done_status=false;
      this.prev_country_code=this.country_code;
      this.prev_send_to1=this.send_to1;
    }

    if(this.type1==true && this.type2==false){
      this.Check=false;
    }
  }

  select_type2(value){
    
    if(this.type1==true && !this.type2==true){
      this.message_type=2;
      this.done_status=false;
    }else if(this.type1==true && this.type2==true){
      this.message_type=0;
    }else if(this.type1==false && this.type2==false){
      this.message_type=1;
    }else{
      this.message_type='';
    }
  
    if(value){
      this.send_to2='';
      this.prev_send_to2=this.send_to2;
    }else{
      this.send_to2=this.email;
      this.prev_send_to2=this.send_to2;

    }

    if(this.type1==false && this.type2==true){
      this.Check=false;
    }
  }

  add_alert_api(){
    //(this.phone)
    this.send_to1=this.phone;
    this.process=true;
    this.disabled=true;
    let req:any;
    let active_flag:any;
    if(this.Check){
      active_flag=1;
    }else{
      active_flag=0;
    }
    if(this.type1==true && this.type2==true){
      let code=encodeURIComponent(this.country_code);
      req='alarm-config?sensor_id='+this.sensor_id+'&user_id='+this.user_id+'&message_type=2&send_to='+this.send_to1+'+'+this.send_to2+'&active_flag='+active_flag+'&country_code='+code;
    }else if(this.type1==true && this.type2==false){
      let code=encodeURIComponent(this.country_code);
      req='alarm-config?sensor_id='+this.sensor_id+'&user_id='+this.user_id+'&message_type=0&send_to='+this.send_to1+'&active_flag='+active_flag+'&country_code='+code;
    }else if(this.type1==false && this.type2==true){
      req='alarm-config?sensor_id='+this.sensor_id+'&user_id='+this.user_id+'&message_type=1&send_to='+this.send_to2+'&active_flag='+active_flag+'&country_code=';
    }else{
      req='alarm-config?sensor_id='+this.sensor_id+'&user_id='+this.user_id+'&message_type=&send_to=&active_flag='+active_flag+'&country_code=';

    }
  
    //(req);
   this.apiservice.apicallsignup(req)
        .subscribe(
          resultArray => {
            this.done_status=true;
            setTimeout(()=>{
              this.modalCtrl.dismiss(true);
             },500)
            let res:any=[];
            res=resultArray;
           
            this.process=false;
            this.disabled=false;
            this.get_alert_api();
          },
          error => {
            
            //(error);
            this.process=false;
            this.disabled=false;
            swal(
              '',
              'Database error. Please try again later.',
              'error',
              {  closeOnClickOutside: false  }
              ).then(() => {
              
              })
           })
  }

  


  async alert_change(data) {
    
  if(this.Check){
    this.type1=false;
    this.type2=false;
    this.phone='';
    this.active_flag=1;
  }else{
    this.active_flag=0;
  }
}
 
  edit() {
   
  }

  
  close(){
    this.modalCtrl.dismiss()
    }
}
